import s from "assets/scss/ExploreOurBrands.module.scss"

import cx from "classnames"
import { Link } from "react-router-dom"

import brandChill from "assets/img/brand-chill.svg"
import brandDlmLounge from "assets/img/brand-dlm-lounge.svg"
import brandEatUp from "assets/img/brand-eatup.svg"
import brandMuggInn from "assets/img/brand-mugginn.svg"
import brandMinipack from "assets/img/brand-minipack.svg"
import brandSnacknpack from "assets/img/brand-snacknpack.svg"
import brandLagom from "assets/img/brand-lagom.svg"
import brandSimitSarayi from "assets/img/brand-simit-sarayi.svg"
import brandSubway from "assets/img/brand-subway.svg"

import Img from "components/Img"
import ButtonCircle from "components/ButtonCircle"
import IconArrow from "components/Icon/IconArrow"
import { useTranslation } from "react-i18next"

type Props = {
  withIntro?: boolean
}

const ExploreOurBrands = ({ withIntro = true }: Props) => {
  const { t } = useTranslation()

  return (
    <section className={s.exploreOurBrands}>
      <div className={s.invitation}>
        {withIntro && <h3 className={s.desc}>{t("exploreOurBrands.title")}</h3>}
        <div className={s.brandsW}>
          <div className={cx(s.brandW, s.chill)} data-parallax data-speed-y="0.2" data-direction-y="-1">
            <Img src={brandChill} objectFit="contain"></Img>
          </div>
          <div className={cx(s.brandW, s.snacknpack)} data-parallax data-speed-y="0.2" data-direction-y="1">
            <Img src={brandSnacknpack} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.brandW, s.mugginn)}
            data-parallax
            data-speed-y="0.3"
            data-direction-y="-1"
            data-speed-x="0.2"
            data-direction-x="1"
          >
            <Img src={brandMuggInn} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.brandW, s.eatup)}
            data-parallax
            data-speed-y="0.3"
            data-direction-y="1"
            data-speed-x="0.2"
            data-direction-x="-1"
          >
            <Img src={brandEatUp} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.brandW, s.minipack)}
            data-parallax
            data-speed-y="0.5"
            data-direction-y="1"
            data-speed-x="0.3"
            data-direction-x="1"
          >
            <Img src={brandMinipack} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.brandW, s.dlmLounge)}
            data-parallax
            data-speed-y="0.3"
            data-direction-y="-1"
            data-speed-x="0.4"
            data-direction-x="1"
          >
            <Img src={brandDlmLounge} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.brandW, s.lagom)}
            data-parallax
            data-speed-y="0.1"
            data-direction-y="1"
            data-speed-x="0.2"
            data-direction-x="-1"
          >
            <Img src={brandLagom} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.brandW, s.subway)}
            data-parallax
            data-speed-y="0.3"
            data-direction-y="1"
            data-speed-x="0.1"
            data-direction-x="-1"
          >
            <Img src={brandSubway} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.brandW, s.simitSarayi)}
            data-parallax
            data-speed-y="0.2"
            data-direction-y="1"
            data-speed-x="0.2"
            data-direction-x="1"
          >
            <Img src={brandSimitSarayi} objectFit="contain"></Img>
          </div>
        </div>
      </div>
      <Link to={`/${t("routes.ourBrands")}`} className={s.btnW}>
        <ButtonCircle
          text={{ part1: t("exploreOurBrands.btnText.p1"), part2: t("exploreOurBrands.btnText.p2") }}
          icon={<IconArrow fill="#2b2b2b" />}
        ></ButtonCircle>{" "}
      </Link>
    </section>
  )
}

export default ExploreOurBrands
