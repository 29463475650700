import s from "assets/scss/OurBrands.module.scss"
import { useEffect, useRef, useState } from "react"

// import Lottie from "lottie-react"
import gsap from "gsap"
import axios from "axios"
import cx from "classnames"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

import charSip from "assets/img/char-sip-with-bg.svg"
import IconArrow from "components/Icon/IconArrow"
import Img from "components/Img"
import { api, IBrandCard } from "typess"
import BrandCard from "../components/BrandCard"

interface IFilter {
  id: string
  title: string
}

const OurBrands = () => {
  const brandsRef = useRef<HTMLDivElement | null>(null)
  const filterRef = useRef<HTMLDivElement | null>(null)

  const [filters, setFilters] = useState<IFilter[]>([])
  const [brands, setBrands] = useState<IBrandCard[]>([])

  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  // const [currentBrand, setCurrentBrand] = useState(null)

  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/markalar")
    } else {
      navigate("/our-brands")
    }
  }, [i18n.language])

  const fetchBrands = async (ids: string[] = []) => {
    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await axios.get<IBrandCard[]>(`${api}brandCards.php`, {
        params: {
          language: i18n.language,
          listID: ids.length !== 0 ? ids.reduce((f, s) => `${f},${s}`) : ids,
        },
      })

      setBrands([...data])
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        // 👇️ const data: CreateUserResponse
        const { data } = await axios.get<IFilter[]>(`${api}brandTypes.php`, {
          params: {
            language: i18n.language,
          },
        })
        setFilters([...data])
        return data
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message)
          // 👇️ error: AxiosError<any, any>
          return error.message
        } else {
          console.log("unexpected error: ", error)
          return "An unexpected error occurred"
        }
      }
    }

    fetchBrands()
    fetchFilters()
  }, [i18n.language])

  useEffect(() => {
    gsap.to(filterRef.current, {
      pointerEvents: "none",
    })

    gsap.to(brandsRef.current, {
      autoAlpha: 0,
      duration: 0.4,
      onComplete: () => {
        fetchBrands(activeFilters).then(() => {
          gsap.to(brandsRef.current, {
            autoAlpha: 1,
            delay: 0.4,
            duration: 0.6,
            onComplete: () => {
              gsap.to(filterRef.current, {
                pointerEvents: "auto",
                opacity: 1,
              })
            },
          })
        })
      },
    })
  }, [activeFilters])

  const handleFilters = (id: string) => {
    if (activeFilters.includes(id)) {
      const filtered = activeFilters.filter((filter) => {
        return filter !== id
      })
      return setActiveFilters(filtered)
    }
    setActiveFilters([...activeFilters, id])
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t("titleAndDesc.preTitle")}${t("titleAndDesc.ourBrands.title")}`}</title>
        <meta name="description" content={`${t("titleAndDesc.ourBrands.desc")}`}></meta>
        <link rel="canonical" href={`${t("titleAndDesc.ourBrands.canonical")}`} />
      </Helmet>
      <main className={s.ourBrands}>
        <section className={s.intro}>
          <div className={s.titleW}>
            <h1 className={s.title}>{t("ourBrands.title.p1")}</h1>
            <h1 className={s.title}>{t("ourBrands.title.p2")}</h1>
          </div>
          <p className={s.desc}>{t("ourBrands.desc")}</p>
          <div className={s.imgW}>
            <Img src={charSip} objectFit="contain"></Img>
          </div>
        </section>
        <section className={s.brandsFilter}>
          <div className={s.oWrapper}>
            <div className={cx(s.filtersW, { [s.open]: isFilterOpen })}>
              <h2 className={s.filterTitle} onClick={() => setIsFilterOpen((prev) => !prev)}>
                {t("ourBrands.filter.title")}
                <span className={s.iconW}>
                  {/* <div className={s.lottieW} ref={lottieRef}>
                  <Lottie
                    animationData={loading}
                    loop={true}
                    className={s.lottie}
                    style={{ width: "200%", height: "200%" }}
                  />
                </div> */}

                  <IconArrow fill="#282828" rotate={45} scale={1.25}></IconArrow>
                </span>
              </h2>
              <div className={s.filters} ref={filterRef}>
                {Array.isArray(filters) &&
                  filters.map((filter) => {
                    return (
                      <p
                        className={cx(s.filter, {
                          [s.active]: activeFilters.includes(filter.id),
                        })}
                        onClick={() => handleFilters(filter.id)}
                        key={filter.id}
                      >
                        {`${filter.title}${parseInt(filter.id) !== filters.length - 1 ? "," : ""}`}
                      </p>
                    )
                  })}
                <div
                  className={cx(s.clearFilters, {
                    [s.active]: activeFilters.length !== 0,
                  })}
                  onClick={() => setActiveFilters([])}
                >
                  <p className={s.text}>Clear Filter</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={s.brandsW} ref={brandsRef}>
          {Array.isArray(brands) &&
            brands.map((brand) => {
              return <BrandCard key={brand.id} brandCard={brand}></BrandCard>
            })}
        </section>
      </main>
    </>
  )
}

export default OurBrands
