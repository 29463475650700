import s from "assets/scss/PrivacyPolicyText.module.scss"
import { useTranslation } from "react-i18next"

type Props = {
  setConfirmation: (status: any) => void
  path: string
}

const PrivacyPolicy = ({ setConfirmation, path = "/" }: Props) => {
  const { t, i18n } = useTranslation()

  const handleConfirmation = () => {
    setConfirmation((prev: boolean) => !prev)
  }

  return (
    <>
      {i18n.language === "EN" ? (
        <div className={s.privacyPolicyText}>
          <small className={s.small} onClick={handleConfirmation}>
            {t("privacyPolicy.text")}{" "}
          </small>
          <a className={s.link} href={path} rel="noreferrer" target="_blank">
            {t("privacyPolicy.link.text")}
          </a>
        </div>
      ) : (
        <div className={s.privacyPolicyText}>
          <a className={s.link} href={path} rel="noreferrer" target="_blank">
            {t("privacyPolicy.link.text")}
          </a>
          <small className={s.small} onClick={handleConfirmation}>
            {" "}
            {t("privacyPolicy.text")}
          </small>
        </div>
      )}
    </>
  )
}

export default PrivacyPolicy
