import s from "assets/scss/BrandCard.module.scss"

import { useTranslation } from "react-i18next"

import Img from "components/Img"
import { useBrandStore } from "store/brandStore"
import { useModalStore } from "store/modalStore"
import { IBrandCard } from "typess"
import InfiniteBrandDetail from "./InfiniteBrandDetail"

type Props = {
  brandCard: IBrandCard
}

const BrandCard = ({ brandCard }: Props) => {
  const modalStore = useModalStore()
  const brandsStore = useBrandStore()
  const { t } = useTranslation()

  const handleBrandDetail = () => {
    modalStore.setContent(<>{<InfiniteBrandDetail />}</>)
    brandsStore.setCurrentBrand(brandCard.id)
    modalStore.toggle()
  }

  return (
    <div className={s.brandCard} onClick={handleBrandDetail}>
      <div className={s.imgW}>
        <Img src={brandCard.logo} />
      </div>
      <div className={s.content}>
        <p className={s.brandInfo}>{`${brandCard.name} | ${brandCard.type}`}</p>
        <p className={s.info}>{brandCard.summary}</p>
        <div className={s.cardButton}>
          <p className={s.text}>{t("ourBrands.card.btnText")}</p>
        </div>
      </div>
    </div>
  )
}

export default BrandCard
