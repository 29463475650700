import s from "assets/scss/Reservation.module.scss"
import { FormEvent, useEffect, useRef, useState } from "react"

import axios from "axios"
import cx from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import Scrollbar from "smooth-scrollbar"
import { ConfigProvider, DatePicker, TimePicker } from "antd"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"

import iconBin from "assets/icon/icon-bin.svg"
import plus from "assets/icon/plus.svg"
import banner from "assets/img/reservation-banner.jpg"

import CounterInput from "components/FormInputs/CounterInput"
import SelectInput from "components/FormInputs/SelectInput"
import PrivacyPolicy from "components/PrivacyPolicy"
import Img from "components/Img"
import { useWindowSize } from "hooks"
import { useScrollLockStore } from "store/scrollLockStore"
import { api, breakpoints } from "typess"
import { useFeedbackStore } from "store/feedBackStore"

type PetInfo = {
  id: string
  type: string
  amount: number
}

const Reservation = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const flightNoInputRef = useRef<HTMLInputElement>(null)
  const flightDateInputRef = useRef<any>()
  // const formRef = useRef<HTMLFormElement>(null)

  const [loungeServices, setLoungeServices] = useState<any>([])

  // FORM INPUTS
  const [name, setName] = useState<string | null>(null)
  const [phone, setPhone] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [passenger, setPassenger] = useState<number>(1)
  const [infant, setInfant] = useState<number>(0)
  const [message, setMessage] = useState<string | null>(null)
  const [petInfo, setPetInfo] = useState<PetInfo[]>([])
  const [dateInstance, setDateInstance] = useState<Dayjs | null>(null)
  const [timeInstance, setTimeInstance] = useState<Dayjs | null>(null)

  const [airlineCompany, setAirlineCompany] = useState<string | null>(null)
  const [flightNumber, setFlightNumber] = useState<string | null>(null)
  const [service, setService] = useState(null)
  const [havePet, setHavePet] = useState<boolean>(false)
  const [confirmed, setConfirmed] = useState<boolean>(false)

  const [timeSelected, setTimeSelected] = useState<boolean>(false)
  const [dateSelected, setDateSelected] = useState<boolean>(false)

  const feedbackStore = useFeedbackStore()
  const scrollLockStore = useScrollLockStore()
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()

  const generateUniqueId = () => {
    return `${new Date().valueOf()}`
  }

  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/rezervasyon")
    } else {
      navigate("/reservation")
    }
  }, [i18n.language])

  const petTypeOptions = [
    {
      id: generateUniqueId(),
      value: t("reservation.form.petInfo.options.o1"),
      label: t("reservation.form.petInfo.options.o1"),
    },
    {
      id: generateUniqueId(),
      value: t("reservation.form.petInfo.options.o2"),
      label: t("reservation.form.petInfo.options.o2"),
    },
    {
      id: generateUniqueId(),
      value: t("reservation.form.petInfo.options.o3"),
      label: t("reservation.form.petInfo.options.o3"),
    },
  ]

  const cipLoungeServiceOptions = [
    {
      value: t("reservation.form.service.options.o1"),
      label: t("reservation.form.service.options.o1"),
    },
    {
      value: t("reservation.form.service.options.o2"),
      label: t("reservation.form.service.options.o2"),
    },
  ]

  const airlineCompanyOptions = [
    { value: "AnadoluJet", label: "AnadoluJet" },
    { value: "Atlas Global", label: "Atlas Global" },
    { value: "OnurAir", label: "OnurAir" },
    { value: "Pegasus", label: "Pegasus" },
    { value: "SunExpress", label: "SunExpress" },
    { value: "Türk Hava Yolları", label: "Türk Hava Yolları" },
  ]

  // FETCH DATA
  useEffect(() => {
    const fetchServices = async () => {
      console.log(i18n.language)

      try {
        // 👇️ const data: CreateUserResponse
        const { data } = await axios.get<any>(`${api}loungeServices.php`, {
          params: {
            language: i18n.language,
          },
        })

        setLoungeServices([...data])

        return data
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message)
          // 👇️ error: AxiosError<any, any>
          return error.message
        } else {
          console.log("unexpected error: ", error)
          return "An unexpected error occurred"
        }
      }
    }

    fetchServices()
  }, [i18n.language])

  const addField = () => {
    if (petInfo.length <= 2) {
      setPetInfo([...petInfo, { type: "", amount: 1, id: generateUniqueId() }])
    }
  }

  const deleteField = (index: number) => {
    setPetInfo(
      petInfo.filter((info, i) => {
        return index !== i
      })
    )
  }

  const handleFocus = (e: FormEvent | any) => {
    e.preventDefault()

    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0.1"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "0.3"
    }
  }

  const handlePetInfo = (val: string | number, index: number) => {
    const data = [...petInfo]

    if (typeof val === "string") {
      data.map((item, i) => {
        if (i === index) {
          item.type = val
        }
      })
    } else if (typeof val === "number") {
      data.map((item, i) => {
        if (i === index) {
          item.amount = val
        }
      })
    }

    setPetInfo([...data])
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const formattedDate = {
      date: dateInstance?.format("DD.MM.YYYY"),
      time: dateInstance?.format("HH:mm"),
    }

    const formData = {
      name,
      phone,
      email,
      passenger,
      infant,
      petInfo,
      flightDate: formattedDate.date,
      flightTime: formattedDate.time,
      flightNumber,
      airlineCompany,
      service,
      message,
      reservationInfo: `${timeInstance?.subtract(3, "hour").format("DD/MM/YYYY")} - ${timeInstance
        ?.subtract(3, "hour")
        .format("HH")}:${timeInstance?.format("mm")}`,
      language: i18n.language,
    }

    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await axios.post<any>(
        `${api}reservation.php`,
        {
          ...formData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      feedbackStore.toggleFeedback()
      feedbackStore.setText(data.message)
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  const handleFlightDate = (data: Dayjs | null) => {
    setDateInstance(data)
    setDateSelected(true)
  }

  const handleFlightTime = (data: Dayjs | null) => {
    setTimeInstance(data)
    setTimeSelected(true)
  }

  const handleConfirmation = () => {
    setConfirmed((prev) => !prev)
  }

  // RESET PET INFO FIELDS
  useEffect(() => {
    if (havePet) {
      addField()
    } else {
      setPetInfo([])
    }
  }, [havePet])

  const flightTimeInputRef = useRef<HTMLInputElement>(null)
  const scrollbarRef = useRef<any>(null)
  const formRef = useRef<HTMLDivElement>(null)
  const reservationRef = useRef<HTMLElement>(null)
  const windowSize = useWindowSize()

  useEffect(() => {
    if (windowSize.width && windowSize.width > breakpoints.tablet) {
      if (formRef.current) {
        scrollbarRef.current = Scrollbar.init(formRef.current, {
          damping: 0.075,
          renderByPixels: false,
          delegateTo: reservationRef.current,
        })
      }
    }
  }, [windowSize.width])

  function disabledDate(current: Dayjs) {
    // Only allow selecting days after today
    return current && current < dayjs().startOf("day")
  }

  function range(start: number, end: number) {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const isToday = (dateObj: Dayjs) => {
    if (dateObj) {
      return dateObj.format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY")
    }
  }

  function disabledTime(current: Dayjs | null) {
    if (!current) {
      return {}
    }

    const disabled = {
      disabledHours: () => range(0, dayjs().add(3, "hour").hour()),
      disabledMinutes: () => {
        if (dayjs().add(3, "hour").hour() === timeInstance?.hour()) {
          return range(0, dayjs().minute())
        } else {
          return []
        }
      },
    }

    let checkToday
    if (dateInstance) {
      checkToday = isToday(current)
    }

    if (checkToday) {
      return disabled
    } else {
      return {}
    }
  }

  useEffect(() => {
    setTimeInstance(null)
  }, [dateInstance])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t("titleAndDesc.preTitle")}${t("titleAndDesc.reservation.title")}`}</title>
        <meta name="description" content={`${t("titleAndDesc.reservation.desc")}`}></meta>
        <link rel="canonical" href={`${t("titleAndDesc.reservation.canonical")}`} />
      </Helmet>
      <main className={s.reservation} ref={reservationRef}>
        <section className={s.services}>
          <h1 className={s.title}>{t("reservation.title")}</h1>
          <div className={s.imgW}>
            <Img src={banner}></Img>
          </div>
          <ul className={s.servicesList}>
            {Array.isArray(loungeServices) &&
              loungeServices.map((service, i) => {
                return (
                  <li className={s.listItem} key={i}>
                    <p className={s.itemText}>{service.title}</p>
                  </li>
                )
              })}
          </ul>
        </section>
        <div className={s.formW} ref={formRef}>
          <form className={s.form}>
            {/* NAME SURNAME */}
            <div className={s.row}>
              <div className={s.smallW}>
                <small className={s.smallTop}>{t("reservation.form.name.smallTop")}</small>
                <div className={s.inputW}>
                  <label
                    className={cx(s.label, {
                      [s.hidden]: name,
                    })}
                    htmlFor="name"
                  >
                    {t("reservation.form.name.label")}
                  </label>
                  <input
                    className={s.input}
                    id="name"
                    type="text"
                    ref={nameInputRef}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    onChange={(e) => {
                      setName(e.currentTarget.value)
                    }}
                    required
                  />
                </div>
              </div>
            </div>

            {/* PHONE & EMAIL */}
            <div className={cx(s.row, s.mB)}>
              <div className={s.smallW}>
                <div className={s.inputW}>
                  <label
                    className={cx(s.label, {
                      [s.hidden]: phone,
                    })}
                    htmlFor="phone"
                  >
                    {t("reservation.form.phone.label")}
                  </label>
                  <input
                    className={s.input}
                    id="phone"
                    type="text"
                    ref={phoneInputRef}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    onChange={(e) => {
                      setPhone(e.currentTarget.value)
                    }}
                    required
                  />
                </div>
              </div>

              <div className={s.smallW}>
                <div className={s.inputW}>
                  <label
                    className={cx(s.label, {
                      [s.hidden]: email,
                    })}
                    htmlFor="email"
                  >
                    {t("reservation.form.email.label")}
                  </label>
                  <input
                    className={s.input}
                    id="email"
                    type="email"
                    ref={emailInputRef}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value)
                    }}
                    required
                  />
                </div>
              </div>
            </div>

            {/* PASSENGER & INFANT */}
            <div className={s.row}>
              <div className={s.smallW}>
                <small className={s.smallTop}> {t("reservation.form.passenger.smallTop")}</small>
                <small className={s.smallBottom}>{t("reservation.form.passenger.smallBottom")}</small>
                <div className={s.inputW}>
                  <CounterInput
                    onChange={setPassenger}
                    value={passenger}
                    htmlName="passenger"
                    label={t("reservation.form.passenger.label")}
                    minValue={1}
                  />
                </div>
              </div>

              <div className={s.smallW}>
                <small className={s.smallBottom}>{t("reservation.form.infant.smallBottom")}</small>
                <div className={s.inputW}>
                  <CounterInput
                    onChange={setInfant}
                    value={infant}
                    htmlName="infant"
                    label={t("reservation.form.infant.label")}
                    minValue={0}
                  />
                </div>
              </div>
            </div>

            {/* HAVE ANY PET? */}
            <div className={cx(s.row, s.havePet)}>
              <div className={s.smallW}>
                <small className={cx(s.smallTop, s.dark)}>{t("reservation.form.petInfo.havePets.label")}</small>
                <div className={cx(s.inputW, s.oHidden)}>
                  <div
                    className={cx(s.checkboxInput, { [s.checked]: !havePet })}
                    onClick={() => setHavePet((prev) => !prev)}
                  >
                    <div className={s.btnW}>
                      <div className={cx(s.yes, s.movin)}>
                        <p className={s.movinText}>{t("reservation.form.petInfo.havePets.yes")}</p>
                        <div className={s.circle}></div>
                      </div>
                      <div className={cx(s.no, s.movin)}>
                        <div className={s.circle}></div>
                        <div className={s.movinText}>{t("reservation.form.petInfo.havePets.no")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* PET INFO */}
            <div className={cx(s.petInfoW, { [s.visible]: havePet })}>
              {/* ADD NEW PET */}
              <div className={cx(s.row, s.petInfo)}>
                <button type="button" className={s.addButton} onClick={addField}>
                  <div className={s.plus}>
                    <span className={s.actionIconW}>
                      <img className={s.actionIcon} src={plus} alt="Plus Icon" />
                    </span>
                  </div>
                  <p className={s.btnText}>{t("reservation.form.petInfo.addNewPet")}</p>
                </button>
              </div>

              {/* TYPE OF PET & NUMBER */}
              {petInfo.map((field, i) => {
                return (
                  <div className={cx(s.row, s.petInfo)} key={field.id}>
                    <div className={s.smallW}>
                      <div className={s.inputW}>
                        <SelectInput
                          options={petTypeOptions}
                          label={t("reservation.form.petInfo.petType")}
                          value={petInfo[i].type}
                          onChange={(val: string) => handlePetInfo(val, i)}
                        />
                      </div>
                    </div>
                    <div className={s.smallW}>
                      <div className={s.inputW}>
                        <CounterInput
                          label={t("reservation.form.petInfo.petNumber")}
                          onChange={(val: number) => handlePetInfo(val, i)}
                          value={petInfo[i].amount}
                          htmlName="amount"
                          minValue={1}
                        />
                      </div>
                    </div>
                    <div className={s.deleteField} onClick={() => deleteField(i)}>
                      <div className={s.iconW}>
                        <Img src={iconBin} objectFit="contain"></Img>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            {/* FLIGHT DATE & FLIGHT TIME */}
            <div className={cx(s.row, s.mT)}>
              <div className={s.smallW}>
                <div className={s.inputW}>
                  <ConfigProvider
                    theme={{
                      token: {
                        fontSize: 18,
                        fontFamily: "Moderat",
                        colorText: "#282828",
                        colorTextPlaceholder: "#282828",
                      },
                    }}
                  >
                    <DatePicker
                      className={s.antPicker}
                      placeholder={`${t("reservation.form.flightDate.label")}`}
                      value={dateInstance}
                      format={"DD/MM/YYYY"}
                      onSelect={handleFlightDate}
                      onOpenChange={() => scrollLockStore.toggle()}
                      bordered={false}
                      disabledDate={disabledDate}
                      ref={flightDateInputRef}
                    />
                  </ConfigProvider>
                </div>
              </div>

              <div className={s.smallW}>
                <div className={s.inputW}>
                  <ConfigProvider
                    theme={{
                      token: {
                        fontSize: 18,
                        fontFamily: "Moderat",
                        colorText: "#282828",
                        colorTextPlaceholder: "#282828",
                      },
                    }}
                  >
                    <TimePicker
                      className={s.antPicker}
                      placeholder={`${t("reservation.form.flightTime.label")}`}
                      // value={dateInstance && isToday(dateInstance) ? timeInstance?.add(3, "hour") : timeInstance}
                      value={timeInstance}
                      format={"HH:mm"}
                      onOpenChange={() => scrollLockStore.toggle()}
                      bordered={false}
                      ref={flightTimeInputRef}
                      onSelect={handleFlightTime}
                      disabledTime={() => disabledTime(dateInstance)}
                      // disabled={!dateSelected}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>

            {/* RESERVATION INFO*/}
            {dateInstance && timeInstance && (
              <>
                {i18n.language === "EN" ? (
                  <small className={s.reservationInfo}>
                    You can enter the area at
                    <strong>
                      {` ${timeInstance?.subtract(3, "hour").format("DD/MM/YYYY")} - ${timeInstance
                        ?.subtract(3, "hour")
                        .format("HH")}:${timeInstance?.format("mm")} `}
                    </strong>
                    at the earliest.
                  </small>
                ) : (
                  <small className={s.reservationInfo}>
                    Alana en erken
                    <strong>
                      {` ${timeInstance?.subtract(3, "hour").format("DD/MM/YYYY")} - ${timeInstance
                        ?.subtract(3, "hour")
                        .format("HH")}:${timeInstance?.format("mm")} `}
                    </strong>
                    tarihinde giriş yapabilirsiniz.
                  </small>
                )}
              </>
            )}

            {/* AIRLINE COMPANY */}
            <div className={s.row}>
              <div className={s.smallW}>
                <div className={s.inputW}>
                  <SelectInput
                    options={airlineCompanyOptions}
                    label={t("reservation.form.selectAirlineCompany.label")}
                    value={airlineCompany}
                    onChange={setAirlineCompany}
                  />
                </div>
              </div>
            </div>

            {/* FLIGHT NO */}
            <div className={cx(s.row, s.flightNo)}>
              <div className={s.smallW}>
                <div className={s.inputW}>
                  <label
                    className={cx(s.label, {
                      [s.hidden]: flightNumber,
                    })}
                    htmlFor="flightNo"
                  >
                    {t("reservation.form.flightNo.label")}
                  </label>
                  <input
                    className={s.input}
                    id="flightNo"
                    type="text"
                    ref={flightNoInputRef}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    onChange={(e) => {
                      setFlightNumber(e.currentTarget.value)
                    }}
                    required
                  />
                </div>
              </div>
            </div>

            {/* SELECT A SERVICE */}
            <div className={s.selectServiceW}>
              <div className={s.row}>
                <div className={s.smallW}>
                  <small className={cx(s.smallTop, s.dark)}>{t("reservation.form.service.smallTop")}</small>
                  <div className={s.inputW}>
                    <SelectInput
                      options={cipLoungeServiceOptions}
                      label={t("reservation.form.service.label")}
                      value={service}
                      onChange={setService}
                    />
                  </div>
                </div>
              </div>
              <div className={s.notes}>
                <small className={s.note}>{t("reservation.form.service.notes.n1")}</small>
                <small className={s.note}>{t("reservation.form.service.notes.n2")}</small>
              </div>
            </div>

            {/* MESSAGE */}
            <div className={s.row}>
              <div className={s.textareaW}>
                <label className={s.textareaLabel} htmlFor="message">
                  {t("reservation.form.message.label")}
                </label>
                <textarea
                  className={s.textarea}
                  id="message"
                  onChange={(e) => {
                    setMessage(e.currentTarget.value)
                  }}
                  required
                />
              </div>
            </div>

            {/* CONFIRMATION */}
            <div className={cx(s.row, s.confirmationW)}>
              <div className={s.confirmation}>
                <div className={s.checkbox} onClick={handleConfirmation}>
                  <div className={cx(s.inner, { [s.enabled]: confirmed })}></div>
                </div>
                <PrivacyPolicy setConfirmation={setConfirmed} path={t("contact.personalDataInfo.path")} />
              </div>
            </div>

            <button className={cx(s.sendBtn, { [s.enabled]: confirmed })} onClick={handleSubmit}>
              <p className={s.btnText}>{t("reservation.form.btnText")}</p>
            </button>
          </form>
        </div>
      </main>
    </>
  )
}

export default Reservation
