import { useRef, useState } from "react"
import s from "assets/scss/SelectInput.module.scss"

import cx from "classnames"

import arrow from "assets/icon/arrow.svg"
import Img from "components/Img"
import { useOnClickOutside } from "hooks"

type Option = {
  value: string
  label: string
}

interface SelectInputProps {
  options: Option[]
  onChange: any
  value: any
  label: string
}

const SelectInput: React.FC<SelectInputProps> = ({ options, onChange, value, label }) => {
  const selectInputRef = useRef(null)
  const optionsRef = useRef(null)

  const [isOpen, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)

  const handleClickOutside = () => {
    if (isOpen) setOpen(false)
    // console.log("clicked outside")
  }

  useOnClickOutside(selectInputRef, handleClickOutside)

  return (
    <div className={s.selectInput} onClick={() => setOpen(!isOpen)} ref={selectInputRef}>
      <label className={s.label}> {selectedOption ? selectedOption.label : label}</label>
      {isOpen && (
        <ul className={cx(s.options, { [s.option]: isOpen })} ref={optionsRef}>
          {options.map((option: any) => (
            <li
              key={option.value}
              className={cx(s.option)}
              onClick={() => {
                setSelectedOption(option)
                setOpen(false)
                onChange(option.value)
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      <div className={s.iconW}>
        <div className={s.t}>
          <Img src={arrow} objectFit="contain"></Img>
        </div>
      </div>
    </div>
  )
}

export default SelectInput
