type Props = {
  fill?: string
  rotate?: number
  scale?: number
}

const IconArrowSquare = ({ fill = "#282828", rotate = 0, scale = 1 }: Props) => {
  return (
    <span
      style={{
        transform: `rotate(${rotate}deg) scale(${scale})`,
        transformOrigin: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 8.971 8.971">
        <path
          id="Path_311"
          data-name="Path 311"
          d="M34.786-11.085h-8.8v1.766l5.69.084-5.86,5.86,1.261,1.261,5.86-5.86.084,5.69h1.766Z"
          transform="translate(-25.815 11.085)"
          fill={fill}
        />
      </svg>
    </span>
  )
}

export default IconArrowSquare
