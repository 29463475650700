export const breakpoints = {
  mobile: 640,
  tabletMini: 768,
  tablet: 1024,
  desktop: 1440,
  widescreen: 1920,
}

export const api = "https://paternagroup.com/jd-admin/services/"

export interface IBrandCard {
  id: string
  name: string
  type: string
  logo: string
  summary: string
}

export interface IBrandDetail {
  id: string
  name: string
  type: string
  logo: string
  detailedInfo: string
  linkType: string | null
  buttonLink?: string
  sliderItems: string[]
  bottomImg: string
  airportLocation: string
  iconTag: string
  nextBrandId: string
}

export interface IReservationForm {
  name: string
  phone: string
  email: string
  passenger?: number
  infant?: number
  petInfo?: { type: string; number: number }[]
  flightDate: string
  flightTime: string
  airlineCompany: string
  flightNumber: number
  service: string
  message?: string
}
