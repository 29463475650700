import s from "assets/scss/Modal.module.scss"
import { useEffect, useRef } from "react"

import cx from "classnames"
import gsap from "gsap"
import { useLocation } from "react-router-dom"
import Scrollbar from "smooth-scrollbar"

import close from "assets/icon/close-x.svg"
import { useLockedBody } from "hooks"
import { useModalStore } from "store/modalStore"
import { useScrollLockStore } from "store/scrollLockStore"
import { useScrollToTargetStore } from "store/scrollToTargetStore"
import Img from "./Img"

const Modal = () => {
  const scrollbarRef = useRef<any>(null)
  const contentInnerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const backdropRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  const scrollToTargetStore = useScrollToTargetStore()
  const modalStore = useModalStore()
  const scrollLockStore = useScrollLockStore()
  const [locked, setLocked] = useLockedBody(false, "root")

  useEffect(() => {
    gsap.set(modalRef.current, {
      visibility: "hidden",
      opacity: 0,
      pointerEvents: "none",
    })

    gsap.set(contentRef.current, {
      xPercent: 100,
    })
  }, [])

  useEffect(() => {
    if (modalStore.open) modalStore.toggle()
  }, [location])

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    scrollLockStore.toggle()

    if (modalStore.open && contentInnerRef.current) {
      // mobile and tablet
      setLocked(true)

      gsap.to(modalRef.current, {
        autoAlpha: 1,
        duration: 0,
        pointerEvents: "auto",
      })

      gsap.to(backdropRef.current, {
        autoAlpha: 1,
        duration: 1,
      })

      gsap.to(contentRef.current, {
        xPercent: 0,
        delay: 0.4,
      })

      scrollbarRef.current = Scrollbar.init(contentInnerRef.current, {
        damping: 0.075,
        alwaysShowTracks: true,
        renderByPixels: false,
      })
    } else {
      // mobile and tablet
      setLocked(false)

      gsap.to(modalRef.current, {
        autoAlpha: 0,
        duration: 1,
        pointerEvents: "none",
      })

      gsap.to(backdropRef.current, {
        autoAlpha: 0,
        duration: 0.4,
        delay: 0.4,
        pointerEvents: "none",
        onComplete: () => {
          modalStore.setContent(null)
        },
      })

      gsap.to(contentRef.current, {
        xPercent: 100,
      })

      if (scrollbarRef.current) {
        scrollbarRef.current.destroy()
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [modalStore.open])

  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  const handleModal = () => {
    modalStore.toggle()
  }

  useEffect(() => {
    if (modalStore.scrollToTop) {
      scrollbarRef.current.setPosition(0, 0)
      modalStore.toggleScrollToTop()
    }
  }, [modalStore.scrollToTop])

  return (
    <div className={cx(s.modal)} ref={modalRef}>
      <div className={s.close} onClick={handleModal}>
        <Img src={close} objectFit="contain"></Img>
      </div>
      <div className={s.backdrop} ref={backdropRef}></div>
      <div className={s.content} ref={contentRef} onClick={handleModal}>
        <div className={s.contentInner} ref={contentInnerRef}>
          <aside className={s.aside} onClick={stopPropagation}>
            <div className={cx(s.oH, { [s.expand]: scrollToTargetStore.scrolled })}>{modalStore.content}</div>
          </aside>
        </div>
      </div>
    </div>
  )
}

export default Modal
