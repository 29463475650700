import s from "../assets/scss/Header.module.scss"

import cx from "classnames"
import { Link } from "react-router-dom"

import { useMenuStore } from "store/menuStore"
import logo from "../assets/img/logo-paterna.svg"
import DropdownLang from "./DropdownLang"
import Img from "./Img"
import Menu from "./Menu"

const Header = () => {
  const menuStore = useMenuStore()

  const handleMenu = () => {
    menuStore.toggleMenu()
  }

  return (
    <>
      <header className={s.header}>
        <Link to="/" className={s.logoW}>
          <Img src={logo} objectFit="contain"></Img>
        </Link>
        <div className={s.btnLanguage}>
          <DropdownLang></DropdownLang>
        </div>
        <div
          className={cx(s.zula, { [s.open]: menuStore.open })}
          onClick={handleMenu}
        >
          <div className={s.lines}>
            <div className={cx(s.line, s.horizontal1)}></div>
            <div className={cx(s.line, s.horizontal2)}></div>
            <div className={cx(s.line, s.horizontal3)}></div>
            <div className={s.cross1W}>
              <div className={s.cross1}></div>
            </div>
            <div className={s.cross2W}>
              <div className={s.cross2}></div>
            </div>
          </div>
        </div>
        <Menu></Menu>
      </header>
    </>
  )
}

export default Header
