import s from "assets/scss/InfiniteBrandDetail.module.scss"
import { useEffect, useRef, useState } from "react"

import axios from "axios"
import cx from "classnames"
import gsap, { Power2 } from "gsap"
import i18n from "i18n/i18n"

import { useBrandStore } from "store/brandStore"
import { useModalStore } from "store/modalStore"
import { api, IBrandDetail } from "typess"
import BrandDetail from "./BrandDetail"

const InfiniteBrandDetail = () => {
  const [brands, setBrands] = useState<IBrandDetail[]>([])
  const brandStore = useBrandStore()
  const modalStore = useModalStore()

  const currentBrandRef = useRef(null)
  const nextBrandRef = useRef<any>(null)
  const oWrapperRef = useRef<any>(null)

  const fetchBrand = async (id: string) => {
    try {
      // 👇️ const data: CreateUserResponse
      const data = await axios.get(`${api}brandDetail.php`, {
        params: {
          language: i18n.language,
          id,
        },
      })
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  useEffect(() => {
    const current = brandStore.currentBrandId
    let brands: IBrandDetail[] = []

    if (current) {
      fetchBrand(current).then((res: any) => {
        const brand = res.data
        brands = [...brands, brand]
        const next = res.data.nextBrandId

        if (next) {
          fetchBrand(next).then((res: any) => {
            const brand = res.data
            brands = [...brands, brand]
            setBrands([...brands])

            gsap.set(currentBrandRef.current, {
              overflow: "hidden",
              opacity: 1,
            })

            gsap.set(oWrapperRef.current, {
              y: "0",
              maxHeight: "330px",
              overflow: "hidden",
            })

            modalStore.toggleScrollToTop()
          })
        }
      })
    }
  }, [brandStore.currentBrandId])

  useEffect(() => {
    if (brands.length > 2) {
      setBrands((prev) =>
        prev.filter((items, i) => {
          return i !== 0
        })
      )
    }
  }, [brands])

  const handleNext = (id: string) => {
    const firstTL = gsap
      .timeline({ paused: true })
      .set(oWrapperRef.current, {
        overflow: "visible",
        maxHeight: "300vh",
      })
      .to(currentBrandRef.current, {
        opacity: 1,
        duration: 0.4,
      })
      .to(oWrapperRef.current, {
        y: () => `-${window.innerHeight - 330}px`,
        duration: 1.4,
        ease: Power2.easeInOut,
      })

    firstTL.play().then(() => {
      if (id) {
        brandStore.setCurrentBrand(id)
      }
    })
  }

  // const fetchBrands = async (brandIds: string[]) => {
  //   const promises = []

  //   for (const id of brandIds) {
  //     const res = id && fetchBrand(id)
  //     promises.push(res)
  //   }

  //   const results = await Promise.all(promises)
  //   const actualDatas = results.map((result: any) => result.data)
  //   setBrands([...actualDatas])
  // }

  // const handleNextBrand = () => {
  //   setTimeout(() => {
  //     setBrands((prev) =>
  //       prev.filter((items, i) => {
  //         return i !== 0
  //       })
  //     )
  //   }, 3000)
  // }

  return (
    <div className={s.infiniteBrandDetail}>
      <div className={cx(s.currentBrand)} ref={currentBrandRef}>
        {brands[0] && <BrandDetail brand={brands[0]} handleNext={handleNext} />}
      </div>
      <div className={s.oWrapper} ref={oWrapperRef} style={{ overflow: "hidden" }}>
        <div className={s.nextBrand} ref={nextBrandRef}>
          {brands[1] && <BrandDetail brand={brands[1]} handleNext={handleNext} />}
        </div>
      </div>
    </div>
  )
}

export default InfiniteBrandDetail
