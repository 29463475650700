import s from "../assets/scss/Footer.module.scss"

import cx from "classnames"
import { Link } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"

import Img from "./Img"
import charHi from "assets/img/char-hi.svg"
import IconArrow from "./Icon/IconArrow"
import IconArrowSquare from "./Icon/IconArrowSquare"
import IconFacebook from "./Icon/IconFacebook"
import IconInstagram from "./Icon/IconInstagram"
import ButtonCircle from "./ButtonCircle"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className={s.footer}>
      <div className={s.content}>
        <div className={s.top}>
          <div className={s.reserve}>
            <h5 className={s.title}>
              <Trans i18nKey="footer.title" components={{ br: <br /> }}>
                {t("footer.title")}
              </Trans>
            </h5>
            <Link to={`/${t("routes.reservation")}`} className={s.btnW}>
              <ButtonCircle
                text={{ part1: t("footer.btnText.p1"), part2: t("footer.btnText.p2") }}
                icon={<IconArrow fill="#2b2b2b" />}
              ></ButtonCircle>
            </Link>
            <div className={s.links}>
              <div className={s.linksW}>
                <Link className={s.link} to={`/${t("routes.aboutUs")}`}>
                  {t("footer.links.aboutUs")}
                </Link>
                <Link className={s.link} to={`/${t("routes.ourBrands")}`}>
                  {t("footer.links.ourBrands")}
                </Link>
                <Link className={s.link} to={`/${t("routes.fieldsOfActivity")}`}>
                  {t("footer.links.fieldsOfActivity")}
                </Link>
              </div>
              <div className={s.linksW}>
                <Link className={s.link} to={`/${t("routes.contact")}`}>
                  {t("footer.links.contact")}
                </Link>
                <Link className={s.link} to={`/${t("routes.career")}`}>
                  {t("footer.links.career")}
                </Link>
              </div>
            </div>
          </div>
          <div className={s.sayHi}>
            <div className={s.imgW}>
              <Img src={charHi} objectFit="contain"></Img>
            </div>
            <div className={s.contactInfoW}>
              <div className={s.title}>{t("footer.sayHi")}</div>
              <div className={s.contactInfo}>
                <div className={s.part}>
                  <div className={s.itemW}>
                    <label className={s.label}>{t("footer.addressSection.email")}</label>
                    <a href="mailto:info@paternagroup.com" className={cx(s.item, s.email)}>
                      info@paternagroup.com
                    </a>
                  </div>
                  <div className={s.itemW}>
                    <label className={s.label}>{t("footer.addressSection.phone")}</label>
                    <a href="tel:+9002527925083" className={cx(s.item, s.phone)}>
                      +90 252 792 50 83
                    </a>
                  </div>
                </div>
                <div className={s.part}>
                  <div className={s.itemW}>
                    <label className={s.label}>{t("footer.addressSection.address")}</label>
                    <p className={cx(s.item, s.address)}>
                      Ege Mahallesi Havalimanı Başmüdürlüğü Küme Evleri No:27 Dalaman/MUĞLA
                      <a
                        className={s.btnGetDirection}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.google.com/search?q=Ege%20Mahallesi%20Havaliman%C4%B1%20Ba%C5%9Fm%C3%BCd%C3%BCrl%C3%BC%C4%9F%C3%BC%20K%C3%BCme%20Evleri%20No%3A27%20Dalaman%2FMU%C4%9ELA&rlz=1C5CHFA_enTR995TR995&oq=Ege+Mahallesi+Havaliman%C4%B1+Ba%C5%9Fm%C3%BCd%C3%BCrl%C3%BC%C4%9F%C3%BC+K%C3%BCme+Evleri+No%3A27+Dalaman%2FMU%C4%9ELA&aqs=chrome..69i57.223j0j7&sourceid=chrome&ie=UTF-8&tbs=lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=14623828723745088730&lqi=Ck5FZ2UgTWFoYWxsZXNpIEhhdmFsaW1hbsSxIEJhxZ9tw7xkw7xybMO8xJ_DvCBLw7xtZSBFdmxlcmkgTm86MjcgRGFsYW1hbi9NVcSeTEFIq5ekI1psEAAQARACEAMQBBAFEAYQBxgAGAIYCBgJIk5lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcgZGFsYW1hbiBtdcSfbGEyAnRykgEaY2l2aWxpYW5fcGFzc2VuZ2VyX2FpcnBvcnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUjFhWE5VZVhaQlJSQUKqAUcQASpDIj9lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcoIQ&ved=2ahUKEwjN-6v75Yz8AhWDW_EDHReoA2wQvS56BAgWEAE&sa=X&rlst=f#rlfi=hd:;si:14623828723745088730,l,Ck5FZ2UgTWFoYWxsZXNpIEhhdmFsaW1hbsSxIEJhxZ9tw7xkw7xybMO8xJ_DvCBLw7xtZSBFdmxlcmkgTm86MjcgRGFsYW1hbi9NVcSeTEFIq5ekI1psEAAQARACEAMQBBAFEAYQBxgAGAIYCBgJIk5lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcgZGFsYW1hbiBtdcSfbGEyAnRykgEaY2l2aWxpYW5fcGFzc2VuZ2VyX2FpcnBvcnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUjFhWE5VZVhaQlJSQUKqAUcQASpDIj9lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcoIQ;mv:[[36.7693083,28.801511299999998],[36.714477099999996,28.785583199999994]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2"
                      >
                        {t("footer.addressSection.btnText")}
                        <span className={s.iconW}>
                          <IconArrowSquare />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className={s.social}>
                <a
                  className={s.link}
                  href="https://www.facebook.com/profile.php?id=100088525951805"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconFacebook />
                </a>
                <a className={s.link} href="https://www.instagram.com/paterna_group/" target="_blank" rel="noreferrer">
                  <IconInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={s.copyright}>
          <small className={cx(s.item, s.rights)}>{t("footer.copyright.allRights")}</small>
          <a
            href={`${t("footer.copyright.privacyAndCookie.path")}`}
            target="_blank"
            rel="noreferrer"
            className={cx(s.item, s.privacy)}
          >
            {t("footer.copyright.privacyAndCookie.text")}
          </a>
          <small className={cx(s.item, s.signature)}>
            Made by{" "}
            <a className={s.link} target="_blank" rel="noreferrer" href="https://justdesignfx.com">
              JUST DESIGN FX
            </a>
            <sup>®</sup>{" "}
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
