import { gsap } from "gsap"
import { useEffect, useRef } from "react"
import s from "../assets/scss/Preloader.module.scss"

import { useLockedBody } from "hooks"
import luggages from "../assets/gif/luggages.gif"
import pattern from "../assets/gif/pattern.gif"

const Preloader = () => {
  const ref = useRef(null)
  const [, setLocked] = useLockedBody(false, "root")

  useEffect(() => {
    setLocked(true)
  }, [])

  function up() {
    gsap.to(ref.current, {
      opacity: 0,
      pointerEvents: "none",
      delay: 2,
      onComplete: () => {
        setLocked(false)
        gsap.to(ref.current, {
          display: "none",
        })
      },
    })
  }

  // preload gifs
  useEffect(() => {
    const gifs = [luggages, pattern]

    gifs.forEach((src) => {
      const img = new Image()
      img.src = src
    })
  }, [])

  useEffect(() => {
    // let progress = 0
    let img: any, c: number, tot: number

    img = document.images
    c = 0
    tot = img.length

    const imgLoaded = () => {
      c += 1
      //   progress = ((100 / tot) * c) << 0

      if (c === tot) {
        up()
        return
      }
    }

    const counter = () => {
      for (var i = 0; i < tot; i++) {
        var tImg = new Image()
        tImg.onload = imgLoaded
        tImg.onerror = imgLoaded
        tImg.src = img[i].src
      }
    }

    counter()
  }, [])

  return (
    <div className={s.preloader} ref={ref}>
      <div className={s.gifC}>
        <img src={pattern} alt="Luggages" className={s.gif} />
        <img src={luggages} alt="Luggages" className={s.gif} />
      </div>
    </div>
  )
}

export default Preloader
