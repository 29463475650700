type Props = {
  fill?: string
  stroke?: string
}

const BgCircle = ({ fill = "#000", stroke = "#000" }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 198.999 198.999">
      <path
        id="Path_1304"
        data-name="Path 1304"
        d="M198,99c0,4.186-9.048,7.738-9.575,11.78-.539,4.136,7.28,9.9,6.224,13.847-1.074,4.019-10.732,5.105-12.3,8.894-1.589,3.832,4.461,11.431,2.4,14.989-2.073,3.584-11.682,2.126-14.192,5.392S171.919,166.085,169,169s-11.825-.965-15.1,1.553-1.809,12.118-5.392,14.191c-3.558,2.058-11.157-3.992-14.989-2.4-3.788,1.57-4.874,11.229-8.892,12.3-3.951,1.056-9.711-6.763-13.847-6.223C106.738,188.951,103.185,198,99,198s-7.738-9.047-11.78-9.574c-4.136-.539-9.9,7.279-13.847,6.223-4.018-1.074-5.106-10.733-8.894-12.3-3.831-1.59-11.431,4.461-14.989,2.4-3.583-2.073-2.126-11.683-5.392-14.192S31.913,171.918,29,169s.964-11.824-1.553-15.1-12.118-1.809-14.191-5.392c-2.059-3.558,3.991-11.157,2.4-14.988-1.571-3.789-11.229-4.875-12.3-8.893-1.056-3.951,6.763-9.711,6.224-13.847C9.048,106.737,0,103.184,0,99s9.048-7.738,9.575-11.78c.539-4.136-7.28-9.9-6.223-13.847,1.074-4.018,10.732-5.105,12.3-8.894,1.589-3.832-4.462-11.43-2.4-14.989,2.073-3.583,11.683-2.126,14.192-5.392S26.08,31.912,29,28.995s11.824.965,15.1-1.553S45.908,15.323,49.49,13.25c3.558-2.059,11.157,3.991,14.989,2.4,3.788-1.571,4.874-11.229,8.892-12.3,3.951-1.056,9.711,6.763,13.847,6.224C91.261,9.047,94.814,0,99,0s7.738,9.048,11.78,9.575c4.136.539,9.9-7.279,13.848-6.224,4.018,1.074,5.1,10.733,8.893,12.3,3.832,1.59,11.431-4.461,14.989-2.4,3.583,2.073,2.126,11.682,5.392,14.191S166.086,26.079,169,29s-.965,11.824,1.553,15.1,12.118,1.809,14.192,5.392c2.058,3.558-3.992,11.157-2.4,14.989,1.57,3.788,11.229,4.875,12.3,8.892,1.056,3.951-6.763,9.711-6.224,13.847C188.951,91.261,198,94.813,198,99"
        transform="translate(0.5 0.501)"
        fill={fill}
        stroke={stroke}
        strokeWidth="1"
      />
    </svg>
  )
}

export default BgCircle
