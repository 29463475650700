import s from "assets/scss/ImageSlider.module.scss"

import cx from "classnames"

// import Swiper core and required modules
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import { useSwiperRef, useWindowSize } from "hooks"
import { useEffect, useState } from "react"

import "swiper/css"
import "swiper/css/navigation"
import Img from "./Img"

import arrow from "assets/icon/arrow.svg"
import { breakpoints } from "typess"

type Props = {
  images: string[]
}

const ImageSlider = (props: Props) => {
  const windowSize = useWindowSize()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [visibleSlides, setVisibleSlides] = useState(1)
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()

  useEffect(() => {
    if (windowSize.width && windowSize.width > breakpoints.mobile) {
      setVisibleSlides(2)
    } else {
      setVisibleSlides(1)
    }
  }, [windowSize.width])

  return (
    <div className={s.imageSlider}>
      <Swiper
        style={{ width: "100%", height: "100%" }}
        modules={[Navigation]}
        onSlideChange={(e: any) => setCurrentSlide(e.activeIndex)}
        slidesPerView={visibleSlides}
        spaceBetween={0}
        breakpoints={{
          // when window width is >= 640px
          641: {
            spaceBetween: 50,
            slidesPerView: visibleSlides,
          },
        }}
        speed={600}
        navigation={{
          prevEl,
          nextEl,
          disabledClass: "hidden",
        }}
      >
        {Array.isArray(props.images) &&
          props.images.map((image, i) => {
            return (
              <SwiperSlide key={i}>
                <div className={s.slideImgW}>
                  <img className={s.img} src={image} alt="sample" />
                </div>
              </SwiperSlide>
            )
          })}
      </Swiper>

      <button className={cx(s.btn, s.prev, { [s.disabled]: currentSlide === 0 })} ref={prevElRef}>
        <div className={s.iconW}>
          <div className={s.t}>
            <Img src={arrow} objectFit="contain"></Img>
          </div>
        </div>
      </button>
      <button
        className={cx(s.btn, s.next, { [s.disabled]: currentSlide === props.images.length - visibleSlides })}
        ref={nextElRef}
      >
        <div className={s.iconW}>
          <Img src={arrow} objectFit="contain"></Img>
        </div>
      </button>
    </div>
  )
}

export default ImageSlider
