import { ReactElement, useEffect, useLayoutEffect, useRef } from "react"
import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { useWindowSize } from "hooks"
import { useMenuStore } from "store/menuStore"
import { useModalStore } from "store/modalStore"
import { breakpoints } from "typess"
import { useScrollLockStore } from "store/scrollLockStore"

interface Props {
  children: ReactElement
  location?: any
}

//TODO: UPDATE SCROLLTRIGGER ON HEIGHT CHANGE
const WithSmooth = ({ children, location }: Props) => {
  const scrollerRef = useRef<HTMLDivElement>(null)
  const smooth = useRef<Scrollbar | null>(null)
  const q = gsap.utils.selector(scrollerRef)

  const windowSize = useWindowSize()
  const menuStore = useMenuStore()
  const scrollLockStore = useScrollLockStore()

  const clearAnims = () => {
    ScrollTrigger.getAll().forEach((instance) => {
      instance.kill()
    })

    // This in case a scroll animation is active while the route is updated
    gsap.killTweensOf(window)
  }

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    let ctx: any
    let ctx1: any
    if (smooth.current) {
      smooth.current.destroy()
      console.log("Smooth Destroyed")
    }

    const initSmoothScrollbar = () => {
      class ModalPlugin extends ScrollbarPlugin {
        static pluginName = "modal"

        static defaultOptions = {
          open: false,
        }

        transformDelta(delta: any) {
          return this.options.open ? { x: 0, y: 0 } : delta
        }
      }

      Scrollbar.use(ModalPlugin)

      if (scrollerRef.current) {
        smooth.current = Scrollbar.init(scrollerRef.current, {
          damping: 0.075,
          delegateTo: document,
          alwaysShowTracks: false,
          renderByPixels: false,
        })
        console.log("Smooth Initialized")
      }

      ctx = gsap.context(() => {
        // disable bounce
        gsap.set("body", {
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        })

        gsap.set(scrollerRef.current, {
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        })
      })

      ScrollTrigger.scrollerProxy(scrollerRef.current, {
        scrollTop(value: any) {
          if (smooth.current) {
            if (arguments.length) {
              smooth.current.scrollTop = value
            }
            return smooth.current.scrollTop
          }
        },
      })

      smooth.current?.addListener(ScrollTrigger.update)
      ScrollTrigger.defaults({ scroller: scrollerRef.current })

      initAnimations(smooth.current)

      // Only necessary to correct marker position - not needed in production
      if (document.querySelector(".gsap-marker-scroller-start")) {
        const markers = gsap.utils.toArray('[class *= "gsap-marker"]')

        smooth.current?.addListener(({ offset }: any) => {
          gsap.set(markers, { marginTop: -offset.y })
        })
      }
    }

    const initAnimations = (smoothInstance?: Scrollbar | null) => {
      ctx1 = gsap.context(() => {
        // MARQUEE
        if (q("[data-marquee]").length !== 0) {
          console.log("MARQUEE INITIALIZED")
          gsap.set(q("[data-marquee]"), { xPercent: -100 })

          /* ADD SKEW SECTION */
          let proxy = { skew: 0, scale: 0 }
          let skewSetter = gsap.quickSetter("[data-marquee-sliding]", "skewX", "deg") // fast
          let clamp = gsap.utils.clamp(-20, 20) // don't let the skew go beyond 20 degrees.

          ScrollTrigger.create({
            onUpdate: (self) => {
              let skew = clamp(self.getVelocity() / -200)

              // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
              if (Math.abs(skew) > Math.abs(proxy.skew)) {
                proxy.skew = skew
                gsap.to(proxy, {
                  // xPercent: () => 100 * self.direction,
                  skew: 0,
                  duration: 0.4,
                  ease: "power3",
                  overwrite: true,
                  onUpdate: () => {
                    skewSetter(proxy.skew)
                    // scaleSetter(proxy.scale)
                  },
                })
              }
            },
          })

          q("[data-marquee-sliding]").forEach((marquee) => {
            gsap.to(marquee, {
              xPercent: () => 20 * (marquee.dataset.direction === "1" ? 1 : -1),
              scrollTrigger: {
                trigger: marquee,
                // markers: true,
                scrub: 2,
              },
            })
          })
        }

        // PARALLAX ITEMS
        if (q("[data-parallax]").length !== 0) {
          console.log("PARALLAX ITEMS INITIALIZED")

          if (windowSize.width) {
            if (windowSize.width >= breakpoints.tablet) {
              const parallaxItems = q("[data-parallax]")

              parallaxItems.forEach((item: any) => {
                gsap.to(item, {
                  yPercent: () => 100 * parseFloat(item.dataset.speedY) * parseFloat(item.dataset.directionY),
                  xPercent: () => 100 * parseFloat(item.dataset.speedX) * parseFloat(item.dataset.directionX),
                  scrollTrigger: {
                    trigger: item,
                    scrub: 1,
                    // markers: true,
                  },
                })
              })
            }
          }
        }

        // SCALING IMG
        if (q("[data-scaling-img-wr]").length !== 0) {
          console.log("SCALING IMAGES INITIALIZED")

          const itemWrappers = q("[data-scaling-img-wr]")
          const items = q("[data-scaling-img]")

          gsap.set(itemWrappers, {
            overflow: "hidden",
          })

          gsap.set(items, {
            scale: 1.2,
            transformOrigin: "center",
          })

          itemWrappers.forEach((item, i) => {
            gsap.to(items[i], {
              scale: 1.05,
              scrollTrigger: {
                trigger: item,
                scrub: true,
                // markers: true,
              },
            })
          })
        }

        // WOOO
        // console.log("WOO", Array.isArray(q("[data-wooo]")), q("[data-wooo]"), document.querySelector("[data-wooo]"))
        if (q("[data-wooo]").length !== 0) {
          console.log("WOOOHOO INITIALIZED")

          gsap.set(q("[data-wooo-strip]"), {
            rotation: 20,
            xPercent: -10,
            scale: 1.2,
          })

          q("[data-wooo-item]").forEach((item, i) => {
            gsap.set(item, {
              xPercent: 100,
              yPercent: -50 * (i + 1),
              rotation: -10 * i,
            })
          })

          q("[data-wooo-item-button]").forEach((item, i) => {
            gsap.set(item, {
              xPercent: 1000,
              yPercent: -1000,
              opacity: 0,
            })
          })

          const wooTL = gsap.timeline({ paused: true })

          wooTL
            .add("s")
            .to(
              q("[data-wooo-strip]"),
              {
                xPercent: -235,
                yPercent: 0,
                rotation: 0,
                scale: 1,
              },
              "s"
            )
            .to(
              q("[data-wooo-item]"),
              {
                xPercent: 0,
                yPercent: 0,
                rotation: 0,
              },
              "s"
            )
            .to(
              q("[data-wooo-item-button]"),
              {
                xPercent: 0,
                yPercent: 0,
                opacity: 1,
              },
              "s"
            )

          ScrollTrigger.create({
            trigger: q("[data-wooo]")[0],
            start: "center center",
            end: "bottom+=200% top",
            scrub: 1,
            // markers: true,
            pin: true,
            animation: wooTL,
            // fastScrollEnd: true,
          })
        }
      })
    }

    if (windowSize.width) {
      if (windowSize.width > breakpoints.tablet) {
        initSmoothScrollbar()
      } else {
        initAnimations(null)
      }
    }

    return () => {
      clearAnims()
      ctx && ctx.revert()
      ctx1 && ctx1.revert()
      // smooth.current && smooth.current?.destroy()
      // console.log("Smooth Destroyed")
    }
  }, [windowSize.width, location])

  // SCROLL LOCK FOR SMOOTH SCROLLBAR
  useEffect(() => {
    if (scrollLockStore.locked) {
      smooth.current && smooth.current.updatePluginOptions("modal", { open: true })
    } else {
      smooth.current && smooth.current.updatePluginOptions("modal", { open: false })
    }
  }, [scrollLockStore.locked])

  return <div ref={scrollerRef}>{children}</div>
}

export { WithSmooth }
