import create from "zustand"

interface ScrollToTargetState {
  scrolled: boolean
  toggle: () => void
}

const useStore = create<ScrollToTargetState>((set, get) => ({
  scrolled: false,
  toggle: () => set({ scrolled: !get().scrolled }),
}))

export const useScrollToTargetStore = useStore
