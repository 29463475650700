type Props = {
  fill?: string
  rotate?: number
  scale?: number
}

const IconArrow = ({ fill = "#000", rotate = 0, scale = 1 }: Props) => {
  return (
    <span
      style={{
        display: "inline-flex",
        transform: `rotate(${rotate}deg) scale(${scale})`,
        transformOrigin: "center",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="21.106" height="21.106" viewBox="0 0 21.106 21.106">
        <path
          id="Path_287"
          data-name="Path 287"
          d="M36.739-11.085H26.612v2.032l6.547.1L21.815,2.388l1.451,1.451L34.611-7.505l.1,6.547h2.032Z"
          transform="translate(-12.711 -7.587) rotate(45)"
          fill={fill}
        />
      </svg>
    </span>
  )
}

export default IconArrow
