import create from "zustand"

interface MenuState {
  open: boolean
  toggleMenu: () => void
}

const useStore = create<MenuState>((set, get) => ({
  open: false,
  toggleMenu: () => set({ open: !get().open }),
}))

export const useMenuStore = useStore
