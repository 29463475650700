import s from "assets/scss/BrandDetail.module.scss"
import ImageSlider from "./ImageSlider"
import Img from "./Img"

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { IBrandDetail } from "typess"
import ButtonHorizontal from "./ButtonHorizontal"

type Props = {
  brand: IBrandDetail
  handleNext: any
}

const BrandDetail = ({ brand, handleNext }: Props) => {
  const { t } = useTranslation()

  const handleNextInner = () => {
    handleNext(brand.nextBrandId)
  }

  const renderButton = () => {
    switch (brand.linkType) {
      case "reservation":
        return (
          <a href={`${t("brandDetail.reservationLink")}`} target="_blank" rel="noreferrer" className={s.reservationW}>
            <ButtonHorizontal backgroundColor="#BDF98A" text={t("brandDetail.reservation")} />
          </a>
        )
      case "external":
        return (
          <a href={brand.buttonLink} target="_blank" rel="noreferrer" className={s.seeMenuW}>
            <ButtonHorizontal backgroundColor="#BDF98A" text={t("brandDetail.seeMenu")} />
          </a>
        )
      default:
        break
    }
  }

  // const handleNextBrand = (id: string) => {
  //   setTimeout(() => {
  //     brandStore.setCurrentBrand(id)
  //     scrollToTargetStore.toggle()
  //   }, 1500)
  // }

  // const fetchNextBrand = async () => {
  //   try {
  //     // 👇️ const data: CreateUserResponse
  //     const { data } = await axios.get<IBrandDetail>(`${api}brandDetail.php`, {
  //       params: {
  //         language: i18n.language,
  //         id: brand.nextBrandId,
  //       },
  //     })
  //     setNextBrand(data)
  //     return data
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.log("error message: ", error.message)
  //       // 👇️ error: AxiosError<any, any>
  //       return error.message
  //     } else {
  //       console.log("unexpected error: ", error)
  //       return "An unexpected error occurred"
  //     }
  //   }
  // }

  return (
    <div className={s.brandDetail} data-brand-id={brand.id}>
      <div className={s.iconTag}>
        <Img src={brand.iconTag} objectFit="contain"></Img>
      </div>
      <div className={s.logoC}>
        <Img src={brand.logo} objectFit="contain"></Img>
      </div>
      <div className={s.brandInfo}>
        <div className={s.info}>
          <small className={s.title}>{`${t("brandDetail.brandName")}:`}</small>
          <small className={s.text}>{brand.name}</small>
        </div>
        <div className={s.right}>
          <div className={s.info}>
            <small className={s.title}>{`${t("brandDetail.brandType")}:`}</small>
            <small className={s.text}>{brand.type}</small>
          </div>
          {brand.linkType && <div className={s.infoBtnW}>{renderButton()}</div>}
        </div>
      </div>
      <div className={s.detailedInfoW}>
        <div className={s.infoW}>
          <small className={s.detailTitle}>
            <div className={s.doodle}>
              <Img src={brand.bottomImg} objectFit="contain"></Img>
            </div>
          </small>
          <p className={s.detailInfo}>{brand.detailedInfo}</p>
        </div>
        {brand.airportLocation.length !== 0 && (
          <div className={s.infoW}>
            <small className={s.detailTitle}>{`${t("brandDetail.airportLocation")}:`}</small>
            <ul className={s.detailInfo}>
              {Array.isArray(brand.airportLocation) &&
                brand.airportLocation.length !== 0 &&
                brand.airportLocation.map((location, i) => {
                  return (
                    <li className={s.par} key={i}>
                      {/* <span className={s.bullet}></span> */}
                      <p>{location}</p>
                    </li>
                  )
                })}
            </ul>
          </div>
        )}
      </div>
      {brand.sliderItems.length === 1 ? (
        <div className={s.singleImgW}>
          <Img src={brand.sliderItems[0]}></Img>
        </div>
      ) : (
        <div className={s.sliderW}>
          <ImageSlider images={brand.sliderItems}></ImageSlider>
        </div>
      )}
      <div className={s.btnW} onClick={handleNextInner}>
        <ButtonHorizontal
          text={`${t("brandDetail.nextBrand.p1")} ${t("brandDetail.nextBrand.p2")}`}
          backgroundColor="#effffd"
        ></ButtonHorizontal>
      </div>
    </div>
  )
}

export default BrandDetail
