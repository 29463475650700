import s from "assets/scss/AboutUs.module.scss"

import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import cx from "classnames"

import charBinocular from "assets/img/char-binocular-with-bg.png"
import charHere from "assets/img/char-here-with-bg.png"
import charMoon from "assets/img/char-moon.png"
import menu1 from "assets/img/menu1.png"
import menu2 from "assets/img/menu2.png"
import menu3 from "assets/img/menu3.png"
import menu4 from "assets/img/menu4.png"
import menu5 from "assets/img/menu5.png"
import arrow from "assets/icon/arrow.svg"

import ExploreOurBrands from "components/ExploreOurBrands"
import Img from "components/Img"

// Import Swiper styles
import { useSwiperRef } from "hooks"
import "swiper/css"
import "swiper/css/navigation"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { Helmet } from "react-helmet"

const AboutUs = () => {
  const sliderItems = [menu1, menu2, menu3, menu4, menu5]
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()

  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/hakkimizda")
    } else {
      navigate("/about-us")
    }
  }, [i18n.language])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t("titleAndDesc.preTitle")}${t("titleAndDesc.aboutUs.title")}`}</title>
        <meta name="description" content={`${t("titleAndDesc.aboutUs.desc")}`}></meta>
        <link rel="canonical" href={`${t("titleAndDesc.aboutUs.canonical")}`} />
      </Helmet>
      <main className={s.aboutUs}>
        <div className={s.titleW}>
          <h1 className={s.title}>{t("aboutUs.title.p1")}</h1>
          <h1 className={s.title}>{t("aboutUs.title.p2")}</h1>
        </div>
        <section className={s.details}>
          <article className={s.textW}>
            <div className={s.parW}>
              <p className={s.par}>{t("aboutUs.desc.p1")}</p>
            </div>
          </article>
          <article className={s.textW}>
            <div className={s.imgW}>
              <Img src={charHere} objectFit="contain"></Img>
            </div>
            <div className={s.parW}>
              <p className={s.par}>{t("aboutUs.desc.p2")}</p>
              <p className={s.par}>{t("aboutUs.desc.p3")}</p>
            </div>
          </article>
        </section>
        <section className={s.slider}>
          <Swiper
            style={{ width: "100%", height: "100%" }}
            modules={[Navigation]}
            speed={600}
            navigation={{
              prevEl,
              nextEl,
              disabledClass: "hidden",
            }}
          >
            {Array.isArray(sliderItems) &&
              sliderItems.map((image, i) => {
                return (
                  <SwiperSlide key={i}>
                    <img className={s.img} src={image} alt="sample" />
                  </SwiperSlide>
                )
              })}
          </Swiper>

          <button className={cx(s.btn, s.prev)} ref={prevElRef}>
            <div className={s.iconW}>
              <div className={s.t}>
                <Img src={arrow} objectFit="contain"></Img>
              </div>
            </div>
          </button>
          <button className={cx(s.btn, s.next)} ref={nextElRef}>
            <div className={s.iconW}>
              <Img src={arrow} objectFit="contain"></Img>
            </div>
          </button>
        </section>
        <section className={s.visMisW}>
          <div className={cx(s.visMis, s.vis)}>
            <div className={s.imgW}>
              <Img src={charBinocular} objectFit="contain"></Img>
            </div>
            <h4 className={s.title}>{t("aboutUs.visMis.vision.title")}</h4>
            <p className={s.par}>{t("aboutUs.visMis.vision.text.p1")}</p>
          </div>
          <div className={cx(s.visMis, s.mis)}>
            <div className={s.imgW}>
              <Img src={charMoon} objectFit="contain"></Img>
            </div>
            <h4 className={s.title}>{t("aboutUs.visMis.mission.title")}</h4>
            <p className={s.par}>{t("aboutUs.visMis.mission.text.p1")}</p>
            <p className={s.par}>{t("aboutUs.visMis.mission.text.p2")}</p>
          </div>
        </section>
        <ExploreOurBrands />
      </main>
    </>
  )
}

export default AboutUs
