import React, { useRef } from "react"
import s from "assets/scss/CounterInput.module.scss"

import cx from "classnames"

import plus from "assets/icon/plus.svg"
import minus from "assets/icon/minus.svg"

interface CounterProps {
  onChange: (value: number) => void
  value: number
  htmlName: string
  label: string
  minValue?: number
  maxValue?: number
}

const CounterInput: React.FC<CounterProps> = ({ onChange, value, htmlName, label, minValue = 0, maxValue = 5 }) => {
  const counterRef = useRef<HTMLInputElement>(null)

  const handleDecrement = () => {
    if (value > minValue) {
      onChange(value - 1)
    }
  }

  const handleIncrement = () => {
    console.log(value)

    if (value < maxValue) {
      onChange(value + 1)
    }
  }

  return (
    <div className={s.counterInput}>
      <label className={s.label} htmlFor={htmlName}>
        {label.toLocaleUpperCase()}
      </label>
      <input className={s.input} id={htmlName} type="number" readOnly ref={counterRef} value={value} required />
      <div className={s.actionW}>
        <button
          type="button"
          className={cx(s.action, s.increase, { [s.disabled]: value === maxValue })}
          onClick={handleIncrement}
        >
          <span className={s.actionIconW}>
            <img className={s.actionIcon} src={plus} alt="Plus Icon" />
          </span>
        </button>
        <button
          type="button"
          className={cx(s.action, s.decrease, { [s.disabled]: value === minValue })}
          onClick={handleDecrement}
        >
          <span className={s.actionIconW}>
            <img className={s.actionIcon} src={minus} alt="Minus Icon" />
          </span>
        </button>
      </div>
    </div>
  )
}

export default CounterInput
