import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import { en } from "./en/translation"
import { tr } from "./tr/translation"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "TR",
    lng: localStorage.getItem("i18nextLng") || "TR",
    returnObjects: true,
    resources: {
      EN: en,
      TR: tr,
    },
  })

export default i18n
