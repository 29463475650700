import React, { Fragment, ReactElement } from "react"
import s from "../assets/scss/M2.module.scss"

type Props = {
  children: ReactElement
  direction?: number
}
const M2 = ({ direction = 1, children = <></> }: Props) => {
  return (
    <div className={s.marquee} data-marquee>
      <div className={s.marqueeInner} data-marquee-sliding data-direction={direction}>
        {Array.from(Array(10)).map((item, i) => {
          return <Fragment key={i}>{children}</Fragment>
        })}
      </div>
    </div>
  )
}

export default M2
