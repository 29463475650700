import s from "../assets/scss/Home.module.scss"
import { useState } from "react"

import cx from "classnames"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import ButtonCircle from "components/ButtonCircle"
import ButtonHorizontal from "components/ButtonHorizontal"
import ExploreOurBrands from "components/ExploreOurBrands"
import IconArrow from "components/Icon/IconArrow"
import Img from "components/Img"
import M2 from "components/M2"

import luggages from "assets/gif/luggages.gif"
import pattern from "assets/gif/pattern.gif"
import arrow from "assets/icon/arrow.svg"
import brandChill from "assets/img/brand-chill.svg"
import brandDlmLounge from "assets/img/brand-dlm-lounge.svg"
import brandEatUp from "assets/img/brand-eatup.svg"
import brandMinipack from "assets/img/brand-minipack.svg"
import brandMuggInn from "assets/img/brand-mugginn.svg"
import brandSnacknpack from "assets/img/brand-snacknpack.svg"
import catering from "assets/img/catering.jpg"
import charDive from "assets/img/char-dive.svg"
import charFoodiesOnTheGo from "assets/img/char-foodies-on-the-go.svg"
import charPhone from "assets/img/char-phone.svg"
import charWoo from "assets/img/char-wooo.svg"
import cipServices from "assets/img/cip.jpg"
import cipServicesMobile from "assets/img/cip-mobile.png"
import cloudBgBlue from "assets/img/cloud-with-bg-blue.svg"
import cloud from "assets/img/cloud.svg"
import management from "assets/img/management.jpg"
import mickey from "assets/img/mickey.svg"
import sandwich from "assets/img/sandwich.png"
import waiting from "assets/img/waiting.png"
import { useSwiperRef, useWindowSize } from "hooks"
import { breakpoints } from "typess"
import charHere from "../assets/img/char-here.svg"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"

const Home = () => {
  const { t } = useTranslation()
  const windowSize = useWindowSize()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>()

  const sliderItems = [
    { img: { desktop: catering, mobile: catering }, title: "CATERING", desc: "Restoran & Market & Catering" },
    { img: { desktop: cipServices, mobile: cipServicesMobile }, title: "CIP SERVICES", desc: "DLM Lounge" },
    { img: { desktop: management, mobile: management }, title: "MANAGEMENT", desc: "Common Area Services" },
  ]

  return (
    <main className={s.home}>
      <section className={s.hero}>
        <div className={s.textW}>
          <div className={cx(s.line, s.preFlight)}>
            <h1 className={s.title}>Pre-flight</h1>
            <div className={cx(s.imgW, s.luggages)}>
              <Img src={luggages} objectFit="contain"></Img>
            </div>
          </div>
          <div className={cx(s.line, s.management, s.desktop)}>
            <div className={cx(s.imgW, s.pattern)}>
              <Img src={pattern} objectFit="contain"></Img>
            </div>
            <h1 className={s.title}>Management.</h1>
          </div>
          <div className={cx(s.line, s.management, s.touch)}>
            <h1 className={s.title}>Manage-</h1>
            <h1 className={s.title}>ment.</h1>
            <div className={cx(s.imgW, s.pattern)}>
              <Img src={pattern} objectFit="contain"></Img>
            </div>
          </div>
        </div>
      </section>
      <section className={s.info}>
        <div className={s.charW}>
          <div className={s.imgW}>
            <Img src={charHere} objectFit="contain"></Img>
          </div>
        </div>
        <div className={s.textW}>
          <h3 className={s.title}>{t("home.info.title")}</h3>
          <p className={s.par}>{t("home.info.text")}</p>
          <Link to={`/${t("routes.aboutUs")}`} className={s.btnW}>
            <ButtonHorizontal backgroundColor="#EFFFFD" text={t("home.info.btnText")}></ButtonHorizontal>
          </Link>
        </div>
      </section>
      <section className={s.marqueesW}>
        <div className={s.mWrapper}>
          <M2 direction={1}>
            <h2 className={s.marqueeItem} data-marquee-sliding-inner>
              <span className={cx(s.inner, s.text, s.hollow)}>{t("home.marqueeText")}</span>
              <span className={cx(s.inner, s.imgW)}>
                <Img src={charPhone} objectFit="contain"></Img>
              </span>
              <span className={cx(s.inner, s.text)}>{t("home.marqueeText")}</span>
              <span className={cx(s.inner, s.imgW)}>
                <Img src={charPhone} objectFit="contain"></Img>
              </span>
            </h2>
          </M2>
        </div>

        <div className={s.mWrapper}>
          <M2 direction={-1}>
            <h2 className={s.marqueeItem} data-marquee-sliding-inner>
              <span className={cx(s.inner, s.text, s.hollow)}>{t("home.marqueeText")}</span>
              <span className={cx(s.inner, s.imgW)}>
                <Img src={charPhone} objectFit="contain"></Img>
              </span>
              <span className={cx(s.inner, s.text)}>{t("home.marqueeText")}</span>
              <span className={cx(s.inner, s.imgW)}>
                <Img src={charPhone} objectFit="contain"></Img>
              </span>
            </h2>
          </M2>
        </div>
        {/* <Marquee backgroundColor={"#EFFFFD"} rotate={1} speed={100}>
          <h2 className={s.marqueeItem}>
            <span className={cx(s.inner, s.text, s.hollow)}>Our Services</span>
            <span className={cx(s.inner, s.imgW)}>
              <Img src={charPhone} objectFit="contain"></Img>
            </span>
            <span className={cx(s.inner, s.text)}>Our Services</span>
            <span className={cx(s.inner, s.imgW)}>
              <Img src={charPhone} objectFit="contain"></Img>
            </span>
          </h2>
        </Marquee>
        <Marquee backgroundColor={"#EFEFFF"} rotate={-12} speed={50}>
          <h2 className={s.marqueeItem}>
            <span className={cx(s.text, s.hollow)}>Our Services</span>
            <span className={cx(s.inner, s.imgW)}>
              <Img src={charSip} objectFit="contain"></Img>
            </span>
            <span className={cx(s.inner, s.text)}>Our Services</span>
            <span className={cx(s.inner, s.imgW)}>
              <Img src={charSip} objectFit="contain"></Img>
            </span>
          </h2>
        </Marquee> */}
        <div className={s.floatingImgsW}>
          <div
            className={s.imgW}
            data-parallax
            data-speed-x="0.8"
            data-speed-y="0.8"
            data-direction-y="1"
            data-direction-x="1"
          >
            <Img src={waiting}></Img>
          </div>
          <div
            className={s.imgW}
            data-parallax
            data-speed-x="0.8"
            data-speed-y="0.8"
            data-direction-y="1"
            data-direction-x="1"
          >
            <Img src={sandwich}></Img>
          </div>
        </div>
        <span className={s.borderBottom}></span>
      </section>
      <section className={s.fields}>
        <div className={cx(s.fieldW, s.catering)}>
          <div className={s.fieldCard}>
            <div className={s.imgW} data-scaling-img-wr>
              <div data-scaling-img>
                <Img src={catering}></Img>
              </div>
            </div>
            <h4 className={s.fieldTitle}>{t("home.fields.cards.catering.title")}</h4>
            <small className={s.fieldDetail}>{t("home.fields.cards.catering.detail")}</small>
          </div>
        </div>
        <div className={cx(s.fieldW, s.text)}>
          <div className={s.textW}>
            <h3 className={s.title}>{t("home.fields.title")}</h3>
            <p className={s.par}>{t("home.fields.desc")}</p>
          </div>
        </div>
        <div className={cx(s.fieldW, s.management)}>
          <div className={s.fieldCard}>
            <div className={s.imgW} data-scaling-img-wr>
              <div data-scaling-img>
                <Img src={management}></Img>
              </div>
            </div>
            <h4 className={s.fieldTitle}>{t("home.fields.cards.management.title")}</h4>
            <small className={s.fieldDetail}>{t("home.fields.cards.management.detail")}</small>
          </div>
        </div>
        <div className={cx(s.fieldW, s.cip)}>
          <div className={s.fieldCard}>
            <div className={s.imgW} data-scaling-img-wr>
              <div data-scaling-img>
                <Img src={cipServices}></Img>
              </div>
            </div>
            <h4 className={s.fieldTitle}>{t("home.fields.cards.cip.title")}</h4>
            <small className={s.fieldDetail}>{t("home.fields.cards.cip.detail")}</small>
          </div>
        </div>
        <div className={s.btnCircleW}>
          <Link to={`/${t("routes.fieldsOfActivity")}`} className={s.btnCircleW}>
            <ButtonCircle
              text={{ part1: t("home.fields.btnText.p1"), part2: t("home.fields.btnText.p2") }}
              icon={<IconArrow fill="#2b2b2b" />}
            ></ButtonCircle>
          </Link>
        </div>
        <div className={s.bgImgW}>
          <Img src={charFoodiesOnTheGo} objectFit="contain"></Img>
        </div>
        {window.innerWidth && window.innerWidth <= breakpoints.mobile && (
          <div className={s.sliderMobile}>
            <Swiper
              style={{ width: "100%", height: "100%" }}
              onSlideChange={(e: any) => setCurrentSlide(e.activeIndex)}
              modules={[Navigation]}
              speed={600}
              slidesPerView={1}
              spaceBetween={50}
              navigation={{
                prevEl,
                nextEl,
                disabledClass: "hidden",
              }}
            >
              {Array.isArray(sliderItems) &&
                sliderItems.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className={s.sliderCard}>
                        <div className={s.imgW}>
                          <img
                            className={s.img}
                            src={
                              windowSize.width && windowSize.width > breakpoints.tablet
                                ? item.img.desktop
                                : item.img.mobile
                            }
                            alt="sample"
                          />
                        </div>
                        <h3 className={s.title}>{item.title}</h3>
                        <p className={s.desc}>{item.desc}</p>
                      </div>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
            <button className={cx(s.btn, s.prev, { [s.disabled]: currentSlide === 0 })} ref={prevElRef}>
              <div className={s.iconW}>
                <div className={s.t}>
                  <Img src={arrow} objectFit="contain"></Img>
                </div>
              </div>
            </button>
            <button
              className={cx(s.btn, s.next, { [s.disabled]: currentSlide === sliderItems.length - 1 })}
              ref={nextElRef}
            >
              <div className={s.iconW}>
                <Img src={arrow} objectFit="contain"></Img>
              </div>
            </button>
          </div>
        )}
      </section>
      <section className={s.animated}>
        <div className={s.top}>
          <div className={s.text}>
            <h3 className={s.title}>{t("home.animated.title")}</h3>
            <p className={s.par}>{t("home.animated.text")}</p>
          </div>
          <div
            className={cx(s.imgW, s.charDive)}
            data-parallax
            data-speed-x="0.2"
            data-direction-x="-1"
            data-speed-y="0.1"
            data-direction-y="1"
          >
            <Img src={charDive} objectFit="contain"></Img>
          </div>
          <div className={cx(s.imgW, s.cloud)} data-parallax data-speed-x="0.1" data-direction-x="1">
            <Img src={cloud} objectFit="contain"></Img>
          </div>
          <div className={cx(s.imgW, s.cloudBgBlue)} data-parallax data-speed-x="0.1" data-direction-x="-1">
            <Img src={cloudBgBlue} objectFit="contain"></Img>
          </div>
        </div>
        {window.innerWidth && window.innerWidth > breakpoints.tablet ? (
          <div className={s.wooo} data-wooo>
            {/* <div className={s.mickeyW} data-mickey>
              <Img src={mickey} objectFit="contain"></Img>
            </div> */}
            <div className={s.woooStrip} data-wooo-strip>
              <div className={cx(s.woooItem, s.charWoo)}>
                <Img src={charWoo} objectFit="contain"></Img>
              </div>
              <div className={cx(s.woooItem, s.brandLogo)} data-wooo-item>
                <Img src={brandDlmLounge} objectFit="contain"></Img>
              </div>
              <div className={cx(s.woooItem, s.brandLogo)} data-wooo-item>
                <Img src={brandChill} objectFit="contain"></Img>
              </div>
              <div className={cx(s.woooItem, s.brandLogo)} data-wooo-item>
                <Img src={brandEatUp} objectFit="contain"></Img>
              </div>
              <div className={cx(s.woooItem, s.brandLogo)} data-wooo-item>
                <Img src={brandSnacknpack} objectFit="contain"></Img>
              </div>
              <div className={cx(s.woooItem, s.brandLogo)} data-wooo-item>
                <Img src={brandMinipack} objectFit="contain"></Img>
              </div>
              <div className={cx(s.woooItem, s.brandLogo)} data-wooo-item>
                <Img src={brandMuggInn} objectFit="contain"></Img>
              </div>
              <Link to={`/${t("routes.ourBrands")}`} className={cx(s.woooItem, s.btnW)} data-wooo-item-button>
                <ButtonCircle
                  text={{ part1: t("home.animated.btnText.p1"), part2: t("home.animated.btnText.p2") }}
                  icon={<IconArrow fill="#2b2b2b" />}
                ></ButtonCircle>
              </Link>
            </div>
          </div>
        ) : (
          <ExploreOurBrands withIntro={false}></ExploreOurBrands>
        )}
      </section>
    </main>
  )
}

export default Home
