import s from "assets/scss/Menu.module.scss"
import cx from "classnames"
import { Curtains } from "react-curtains"
import { Link, useLocation } from "react-router-dom"

import terminalPeople from "assets/img/menu4.png"

import { useLockedBody, useWindowSize } from "hooks"
import { useCallback, useEffect } from "react"
import { useMenuStore } from "store/menuStore"
import DropdownLang from "./DropdownLang"
import IconArrow from "./Icon/IconArrow"
import IconInstagram from "./Icon/IconInstagram"
import IconLinkedin from "./Icon/IconFacebook"
import Test from "./Test"
import { useTranslation } from "react-i18next"
import { breakpoints } from "typess"
import { useScrollLockStore } from "store/scrollLockStore"

const Menu = () => {
  const menuStore = useMenuStore()
  const scrollLockStore = useScrollLockStore()
  const [, setLocked] = useLockedBody(false, "root")
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const windowSize = useWindowSize()

  useEffect(() => {
    scrollLockStore.toggle()

    if (menuStore.open) {
      setLocked(true)
    } else {
      setLocked(false)
    }
  }, [menuStore.open])

  useEffect(() => {
    if (menuStore.open) menuStore.toggleMenu()
  }, [location])

  const handleAnim = (e: any, index: number) => {
    const svg: any = Array.from(document.querySelectorAll("[data-animated-svg]"))[index]
    svg.style.strokeDashoffset = "0"
    svg.style.opacity = "1"
  }

  const handleAnimOut = (e: any, index: number) => {
    const svg: any = Array.from(document.querySelectorAll("[data-animated-svg]"))[index]
    const rect = svg.querySelector("[data-inner-rect]")
    // console.log((parseInt(rect.getAttribute("width")) + parseInt(rect.getAttribute("height"))) * 2)
    svg.style.strokeDashoffset = `${(parseInt(rect.getAttribute("width")) + parseInt(rect.getAttribute("height"))) * 2}`
    svg.style.opacity = "0"
  }

  useCallback(() => {
    handleRoutes()
  }, [i18n])

  const handleRoutes = () => {
    const routes = {
      aboutUs: {
        ui: t("menu.aboutUs"),
        path: t("routes.aboutUs"),
        svg: (
          <svg
            data-animated-svg
            viewBox="0 0 552 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={s.animatedSvg}
            style={{ strokeDasharray: "1458", strokeDashoffset: "1458" }}
          >
            <rect
              data-inner-rect
              height="179"
              width="550"
              x="1"
              y="1"
              rx="89.5"
              stroke="#091933"
              strokeWidth="2"
              className={s.animatedSvgRect}
            ></rect>
          </svg>
        ),
      },
      ourBrands: {
        ui: t("menu.ourBrands"),
        path: t("routes.ourBrands"),
        svg: (
          <svg
            data-animated-svg
            viewBox="0 0 632 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={s.animatedSvg}
            strokeDasharray="1618"
            strokeDashoffset="1618"
          >
            <rect
              data-inner-rect
              height="179"
              width="630"
              x="1"
              y="1"
              rx="89.5"
              stroke="#091933"
              strokeWidth="2"
              className={s.animatedSvgRect}
            ></rect>
          </svg>
        ),
      },
      fieldsOfActivity: {
        ui: t("menu.fieldsOfActivity"),
        path: t("routes.fieldsOfActivity"),
        svg: (
          <svg
            data-animated-svg
            viewBox="0 0 832 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={s.animatedSvg}
            strokeDasharray="2018"
            strokeDashoffset="2018"
          >
            <rect
              data-inner-rect
              height="179"
              width="830"
              x="1"
              y="1"
              rx="89.5"
              stroke="#091933"
              strokeWidth="2"
              className={s.animatedSvgRect}
            ></rect>
          </svg>
        ),
      },
      contact: {
        ui: t("menu.contact"),
        path: t("routes.contact"),
        svg: (
          <svg
            data-animated-svg
            viewBox="0 0 442 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={s.animatedSvg}
            strokeDasharray="1238"
            strokeDashoffset="1238"
          >
            <rect
              data-inner-rect
              height="179"
              width="440"
              x="1"
              y="1"
              rx="89.5"
              stroke="#091933"
              strokeWidth="2"
              className={s.animatedSvgRect}
            ></rect>
          </svg>
        ),
      },
      reservation: {
        ui: t("menu.reservation"),
        path: t("routes.reservation"),
        svg: (
          <>
            {windowSize.width && windowSize.width > breakpoints.tablet && (
              <svg
                data-animated-svg
                viewBox="0 0 1252 181"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={s.animatedSvg}
                strokeDasharray="2858"
                strokeDashoffset="2858"
              >
                <rect
                  data-inner-rect
                  height="179"
                  width="1250"
                  x="1"
                  y="1"
                  rx="89.5"
                  stroke="#091933"
                  strokeWidth="2"
                  className={s.animatedSvgRect}
                ></rect>
              </svg>
            )}
          </>
        ),
      },
    }

    return routes
  }

  return (
    <div className={cx(s.navMenu, { [s.open]: menuStore.open })}>
      {windowSize.width && windowSize.width > breakpoints.tablet && (
        <div className={s.thumbnails}>
          <div className={s.wr}>
            <Curtains pixelRatio={Math.min(1.5, window.devicePixelRatio)}>
              <Test>
                <img src={terminalPeople} data-sampler="simplePlaneTexture" alt="" />
              </Test>
            </Curtains>
          </div>
        </div>
      )}

      <div className={s.nav}>
        <ul className={s.navItems}>
          {Object.entries(handleRoutes()).map(([key, value], i) => {
            return (
              <li
                key={i}
                className={s.navItem}
                onMouseEnter={(e) => handleAnim(e, i)}
                onMouseLeave={(e) => handleAnimOut(e, i)}
              >
                {value.svg}
                <Link to={`/${value.path}`} className={s.link}>
                  {value.ui}
                </Link>
              </li>
            )
          })}
        </ul>
        <div className={s.btnLanguage}>
          <DropdownLang></DropdownLang>
        </div>
      </div>

      <div className={s.bottom}>
        <Link to={`/${t("routes.career")}`} className={s.link}>
          {t("menu.career")}
          <span className={s.iconW}>
            <IconArrow fill="#282828"></IconArrow>
          </span>
        </Link>
        <div className={s.social}>
          <a
            className={s.socialLink}
            href="https://www.facebook.com/profile.php?id=100088525951805"
            target="_blank"
            rel="noreferrer"
          >
            <IconLinkedin />
          </a>
          <a className={s.socialLink} href="https://www.instagram.com/paterna_group/" target="_blank" rel="noreferrer">
            <IconInstagram />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Menu
