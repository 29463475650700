import s from "assets/scss/App.module.scss"

import { useEffect, useLayoutEffect, useState } from "react"

import gsap, { Power1 } from "gsap"
import { Route, Routes, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { HelmetProvider } from "react-helmet-async"

import Modal from "components/Modal"
import { WithSmooth } from "hocs/WithSmooth"
import { useWindowSize } from "hooks"
import Footer from "components/Footer"
import Header from "components/Header"
import AboutUs from "pages/AboutUs"
import Career from "pages/Career"
import Contact from "pages/Contact"
import FieldsOfActivity from "pages/FieldsOfActivity"
import OurBrands from "pages/OurBrands"
import Reservation from "pages/Reservation"
import Home from "./pages/Home"
import Feedback from "components/Feedback"
import Preloader from "components/Preloader"
import { getLang } from "utils"
import { useTranslation } from "react-i18next"

function App() {
  const windowSize = useWindowSize()
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const { t, i18n } = useTranslation()

  useLayoutEffect(() => {
    getLang().then((lang) => {
      if (!lang) return
      if (lang === "TR") {
        i18n.changeLanguage("TR")
      } else {
        i18n.changeLanguage("EN")
      }
    })
  }, [])

  useEffect(() => {
    const transitionElements = Array.from(document.querySelectorAll("[data-route-transition]"))

    if (location !== displayLocation) {
      gsap.to(transitionElements, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.3,
        ease: Power1.easeInOut,
        onComplete: () => {
          window.scrollTo(0, 0)
          gsap.to(transitionElements, {
            opacity: 1,
            pointerEvents: "auto",
            duration: 0.4,
            delay: 1,
            ease: Power1.easeInOut,
          })
          setDisplayLocation(location)
        },
      })
    }
  }, [location, displayLocation, windowSize.width])

  return (
    <HelmetProvider>
      <div className={s.app} data-route-transition>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${t("titleAndDesc.preTitle")}${t("titleAndDesc.home.title")}`}</title>
          <meta name="description" content={`${t("titleAndDesc.home.desc")}`}></meta>
          <link rel="canonical" href={`${t("titleAndDesc.home.canonical")}`} />
        </Helmet>
        <Modal />
        <Preloader />
        <Feedback />
        <WithSmooth location={displayLocation}>
          <div className={s.content}>
            <Header />
            <Routes location={displayLocation}>
              <Route index element={<Home />}></Route>

              <Route path="about-us" element={<AboutUs />}></Route>
              <Route path="hakkimizda" element={<AboutUs />}></Route>

              <Route path="our-brands" element={<OurBrands />}></Route>
              <Route path="markalar" element={<OurBrands />}></Route>

              <Route path="fields-of-activity" element={<FieldsOfActivity />}></Route>
              <Route path="faaliyet-alanlari" element={<FieldsOfActivity />}></Route>

              <Route path="contact" element={<Contact />}></Route>
              <Route path="iletisim" element={<Contact />}></Route>

              <Route path="career" element={<Career />}></Route>
              <Route path="kariyer" element={<Career />}></Route>

              <Route path="reservation" element={<Reservation />}></Route>
              <Route path="rezervasyon" element={<Reservation />}></Route>
            </Routes>
            <Footer />
          </div>
        </WithSmooth>
      </div>
    </HelmetProvider>
  )
}

export default App
