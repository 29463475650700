import s from "assets/scss/ButtonCircle.module.scss"
import BgCircle from "./Icon/BgCircle"

interface IText {
  part1?: any
  part2?: any
}

type Props = {
  icon?: JSX.Element | string
  text?: IText
}

const ButtonCircle = ({ icon, text }: Props) => {
  return (
    <button className={s.buttonCircle}>
      <div className={s.btnBg}>
        <div className={s.bgW}>
          <div className={s.rotationW}>
            <BgCircle fill="transparent" />
          </div>
        </div>
        <div className={s.bgW}>
          <div className={s.rotationW}>
            <div className={s.oHidden}>
              <BgCircle fill="#effffd" stroke="none" />
            </div>
          </div>
        </div>
      </div>
      <div className={s.btnInner}>
        <div className={s.line}>
          <p className={s.text}>{text?.part1 && text.part1}</p>
          <span className={s.iconW}>{icon && icon}</span>
        </div>
        <div className={s.line}>
          <p className={s.text}>{text?.part2 && text.part2}</p>
        </div>
      </div>
    </button>
  )
}

export default ButtonCircle
