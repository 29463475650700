import s from "assets/scss/Feedback.module.scss"
import close from "assets/icon/close-x.svg"

import cx from "classnames"

import { useFeedbackStore } from "store/feedBackStore"
import Img from "./Img"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const Feedback = () => {
  const feedbackStore = useFeedbackStore()
  const location = useLocation()

  const handleClose = () => {
    feedbackStore.toggleFeedback()
  }

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    if (feedbackStore.open) {
      setTimeout(() => {
        if (feedbackStore.open) feedbackStore.toggleFeedback()
      }, 6000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [feedbackStore.open])

  useEffect(() => {
    if (feedbackStore.open) feedbackStore.toggleFeedback()
  }, [location])

  return (
    <div className={cx(s.feedback, { [s.visible]: feedbackStore.open })}>
      <div className={s.close} onClick={handleClose}>
        <Img src={close} objectFit="contain"></Img>
      </div>
      <p className={s.text}>{feedbackStore.text}</p>
    </div>
  )
}

export default Feedback
