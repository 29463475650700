import create from "zustand"
import { IBrandDetail } from "typess"

interface BrandsState {
  currentBrandId: string | null
  nextBrandId: string | null
  brands: IBrandDetail[]
  setBrands: (brands: IBrandDetail[]) => void
  setCurrentBrand: (id: string) => void
  setNextBrand: (id: string) => void
}

const useStore = create<BrandsState>((set, get) => ({
  currentBrandId: null,
  nextBrandId: null,
  brands: [],
  setBrands: (brands: IBrandDetail[]) => set(() => ({ brands: [...brands] })),
  setCurrentBrand: (id: string) => set(() => ({ currentBrandId: id })),
  setNextBrand: (id: string) => set(() => ({ nextBrandId: id })),
}))

export const useBrandStore = useStore
