// import pdfPrivacy from "assets/pdf/PrivacyPolicy.pdf"
// import pdfKvkk from "assets/pdf/PersonalDataProcessingInformativeNote.pdf"

export const en = {
  translation: {
    routes: {
      aboutUs: "about-us",
      ourBrands: "our-brands",
      fieldsOfActivity: "fields-of-activity",
      contact: "contact",
      reservation: "reservation",
      career: "career",
    },
    menu: {
      aboutUs: "About Us",
      ourBrands: "Our Brands",
      fieldsOfActivity: "Fields Of Activity",
      contact: "Contact",
      reservation: "DLM Lounge Reservation",
      career: "JOIN THE CREW",
    },
    home: {
      info: {
        title: "You travel, we manage.",
        text: "Paterna Group was founded in 2009 and has continuously expanded its organizational structure and business field thanks to the vision and belief it has since then.",
        btnText: "CLICK FOR MORE",
      },
      marqueeText: "Our Services",
      fields: {
        title: "The food and beverage industry is gaining rapid momentum.",
        desc: "We are able to reach this speed and professionally manage 3 important areas of this industry, namely restaurant management, CIP management and food supply.",
        cards: {
          catering: {
            title: "CATERING",
            detail: "Restaurant & Market & Catering",
          },
          cip: {
            title: "CIP SERVICES",
            detail: "DLM Lounge",
          },
          management: {
            title: "MANAGEMENT",
            detail: "Common Area Services",
          },
        },
        btnText: { p1: "FIELDS", p2: "OF ACTIVITY" },
      },
      animated: {
        title: "Make the most of your time in Dalaman Airport.",
        text: "Explore our selection of brands and enjoy your traveling experience before take off…",
        btnText: { p1: "CHECK", p2: "OUR BRANDS" },
      },
    },
    aboutUs: {
      title: { p1: "We are", p2: "Paterna Group" },
      desc: {
        p1: "Paterna Group was founded in 2009 and has continuously expanded its organizational structure and business field thanks to the vision and belief it has since then.",
        p2: "As Paterna Group, we provide food and beverage service and lounge, catering, to our local and foreign guests within the scope of YDA Dalaman Airport Operation, it provides  services in various sectors such as refreshment service, special day food and beverage service (meeting, festival, opening, promotion).",
        p3: "We are a big team who loves everything about food. We merge this enthusiasm with the fast paced dynamics of an airport.",
      },
      visMis: {
        vision: {
          title: "Our Vision",
          text: {
            p1: "Providing mutual benefit by bringing the fastest, most effective and healthiest solutions to customer demands at all points we serve; to be one of the leading companies in the geography where it is located in airports and socialization areas.",
          },
        },
        mission: {
          title: "Our Mission",
          text: {
            p1: "To strengthen our organization with vertical and horizontal growth activities by penetrating new markets in the field of tourism and service sector, and to grow this discipline in a wide range of services.",
            p2: "Creating new concepts, presenting unique tastes and “aiming to exist together with our stakeholders in the horeca sector.”",
          },
        },
      },
    },
    exploreOurBrands: {
      title: "Explore our selection of brands and enjoy your traveling experience before take off…",
      btnText: { p1: "EXPLORE", p2: "OUR BRANDS" },
    },
    fieldsOfActivity: {
      title: {
        p1: "Fields of",
        p2: "Activity",
      },
      desc: "The food and beverage industry is gaining rapid momentum. We are able to reach this speed and professionally manage 3 important areas of this industry, namely restaurant management, CIP management and food supply.",
      sections: {
        s1: {
          title: "Airport Food <br> & Beverage <br> Services",
          text: {
            p1: "It has opened its first operation in the Dalaman Airport International Terminal since 2009 and has become the preferred food and beverage stop for passengers with its modern architecture restored and designed restaurants.",
            p2: "Today, Dalaman Airport International, Dalaman Airport Domestic, Dalaman Airport General Aviation and Bodrum Airport Domestic, including their activities with a total of 2 branch companies in Turkey maintain and welcomes 10 million visitors per year.",
          },
        },
        s2: {
          title: "Catering, Food & <br> Beverage and <br> Supply Services",
          text: {
            p1: "Paterna Group, which continues its activities in its field outside Dalaman Airport, especially Expo 2016 International Agriculture Fair and Millionfest 2018, carries out various production and distribution operations, especially catering, in compliance with quality standards.",
          },
        },
        s3: {
          title: "Production <br> Facilities",
          text: {
            p1: "Today, Paterna Group produces more than 2000 products with its production facility built on over 500 square meters to meet the food and beverage needs of its units.",
            p2: "With its production department, which operates at high production capacity and has hundreds of recipes, without compromising hygiene standards, the number of quality recipes is increasing day by day.",
          },
        },
        s4: {
          title: "Store <br> Services",
          text: {
            p1: "  Apart from the food and beverage sector, it can also provide merchandising services in areas such as airports, shopping centers and streets.",
          },
        },
      },
    },
    career: {
      title: {
        p1: "Join",
        p2: "the Crew.",
      },
      desc: "  Qualified manpower is essential for Paterna Group. All employees of our company pass certain recruitment standards. Our esteemed employees are valuable individuals who work for the satisfaction of our guests at all points we serve after completing their recruitment process.",
      form: {
        personalInfo: "Personal Info",
        name: "NAME SURNAME*",
        phone: "PHONE NUMBER*",
        email: "EMAIL*",
        message: "MESSAGE",
        file: "UPLOAD YOUR CV*",
        fileInfo: "The file size you can upload is maximum 2 MB, you can only upload pdf or docx files.",
        btnText: "APPLY NOW",
      },
      personalDataForm: {
        text: "Kişisel Verilerin Korunması ve Bilgi Paylaşımı Formu",
        path: "https://paternagroup.com/content/en/kisisel-veriler-ile-ilgili-kvkk-kanunu-kapsaminda-basvuru-formu.pdf",
      },
    },
    privacyPolicy: {
      text: "I consent to the processing of my personal data within the scope of",
      link: {
        text: "Personal Data Protection and Sharing Information.",
        path: "https://paternagroup.com/content/tr/gizlilik-ve-cerez-politikasi.pdf",
      },
    },
    contact: {
      title: { p1: "Between you", p2: "and <br> the sky..." },
      btnText: { p1: "SEE", p2: "ON MAP" },
      addressLabels: {
        address: "ADDRESS",
        email: "EMAIL",
        phone: "PHONE",
      },
      form: {
        name: "NAME SURNAME*",
        phone: "PHONE NUMBER",
        email: "EMAIL*",
        message: "MESSAGE*",
        btnText: "SEND",
      },
      stickers: { ask: "Ask Anything", contact: "Contact Us" },
      personalDataInfo: {
        text: "Kişisel Verilerin Korunması ve Bilgi Paylaşımı",
        path: "https://paternagroup.com/content/en/kisisel-verileri-saklama-ve-imha-politikasi-metni.pdf",
      },
    },
    footer: {
      title: "Reserve your seat <br> at DLM Lounge <br> now!",
      btnText: { p1: "BOOK", p2: "A VISIT" },
      sayHi: "Say Hi!",
      addressSection: {
        address: "ADDRESS",
        email: "EMAIL",
        phone: "PHONE",
        btnText: "GET DIRECTION",
      },
      copyright: {
        allRights: "2022 © Paterna Group | All rights reserved.",
        privacyAndCookie: {
          text: "Privacy & Cookie Policy",
          path: "https://paternagroup.com/content/en/gizlilik-ve-cerez-politikasi.pdf",
        },
      },
      links: {
        aboutUs: "ABOUT US",
        ourBrands: "OUR BRANDS",
        fieldsOfActivity: "FIELDS OF ACTIVITY",
        contact: "CONTACT",
        reservation: "DLM LOUNGE RESERVATION",
        career: "JOIN THE CREW",
      },
    },
    ourBrands: {
      title: {
        p1: "Explore",
        p2: "Our Brands",
      },
      desc: "Make the most of your time in Dalaman Airport, explore our selection of brands and enjoy your traveling experience before take off…",
      filter: {
        title: "All Brands",
      },
      card: {
        btnText: "CLICK FOR MORE",
      },
    },
    reservation: {
      title: "Reserve your seat at DLM Lounge now!",
      form: {
        name: {
          smallTop: "Personal Information",
          label: "NAME SURNAME*",
        },
        phone: { label: "PHONE NUMBER*" },
        email: { label: "EMAIL*" },
        passenger: {
          smallTop: "Passenger Information",
          label: "PASSENGER",
          smallBottom: "+3 Years",
        },
        infant: {
          label: "INFANT",
          smallBottom: "0-2 Years",
        },
        petInfo: {
          havePets: { label: "Have any pet(s)?", yes: "YES", no: "NO" },
          addNewPet: "ADD NEW PET",
          petType: "TYPE OF PET",
          petNumber: "NUMBER",
          options: { o1: "DOG", o2: "CAT", o3: "OTHER" },
        },
        flightDate: {
          label: "FLIGHT DATE",
        },
        flightTime: {
          label: "FLIGHT TIME",
        },
        selectAirlineCompany: {
          label: "SELECT AN AIRLINE COMPANY*",
        },
        flightNo: {
          label: "FLIGHT NO*",
        },
        service: {
          smallTop: "Which premium service would you like to reservation for?",
          label: "SELECT A SERVICE*",
          options: {
            o1: "Domestic Departure CIP Lounge (Inc. Alcoholic Drinks)",
            o2: "Domestic Departure CIP Lounge (Exc. Alcoholic Drinks)",
          },
          notes: {
            n1: "* 0-2 years old infants are free of charge",
            n2: "* Use online booking system and get %10 discount",
          },
        },
        message: {
          label: "MESSAGE",
        },
        btnText: "SEND REQUEST",
      },
    },
    brandDetail: {
      brandName: "Brand Name",
      brandType: "Brand Type",
      reservation: "RESERVATION",
      reservationLink: "https://paternagroup.com/reservation",
      seeMenu: "SEE MENU",
      airportLocation: "Airport Location",
      nextBrand: { p1: "NEXT", p2: "BRAND" },
    },
    titleAndDesc: {
      preTitle: "Paterna Group | ",
      home: {
        title: "Pre-flight Management.",
        desc: "Paterna Group was founded in 2009 and has continuously expanded its organizational structure and business field thanks to the vision and belief it has since then.",
        canonical: "https://paternagroup.com",
      },
      aboutUs: {
        title: "About Us",
        desc: "As Paterna Group, we provide food and beverage service and lounge, catering, to our local and foreign guests within the scope of YDA Dalaman Airport Operation, it provides services in various sectors such as refreshment service, special day food and beverage service (meeting, festival, opening, promotion). We are a big team who loves everything about food. We merge this enthusiasm with the fast paced dynamics of an airport.",
        canonical: "https://paternagroup.com/about-us",
      },
      ourBrands: {
        title: "Our Brands",
        desc: "Make the most of your time in Dalaman Airport, explore our selection of brands and enjoy your traveling experience before take off…",
        canonical: "https://paternagroup.com/our-brands",
      },
      fieldsOfActivity: {
        title: "Fields Of Activity",
        desc: "The food and beverage industry is gaining rapid momentum. We are able to reach this speed and professionally manage 3 important areas of this industry, namely restaurant management, CIP management and food supply.",
        canonical: "https://paternagroup.com/fields-of-activity",
      },
      contact: {
        title: "Contact",
        desc: "Between you and the sky...",
        canonical: "https://paternagroup.com/contact",
      },
      career: {
        title: "Career",
        desc: "Qualified manpower is essential for Paterna Group. All employees of our company pass certain recruitment standards. Our esteemed employees are valuable individuals who work for the satisfaction of our guests at all points we serve after completing their recruitment process.",
        canonical: "https://paternagroup.com/career",
      },
      reservation: {
        title: "Reservation",
        desc: "Reserve your seat at DLM Lounge now!",
        canonical: "https://paternagroup.com/reservation",
      },
    },
  },
}

export default en
