type Props = {
  fill?: string
}

const IconFacebook = ({ fill = "#282828" }: Props) => {
  return (
    <svg
      id="Group_630"
      data-name="Group 630"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="11.401"
      height="20"
      viewBox="0 0 11.401 20"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_485" data-name="Rectangle 485" width="11.401" height="20.001" fill={fill} />
        </clipPath>
      </defs>
      <g id="Group_629" data-name="Group 629">
        <path
          id="Path_2169"
          data-name="Path 2169"
          d="M7.044,11.629V20H3.194V11.629H0V8.236H3.194V7c0-4.587,1.913-7,5.967-7A6.657,6.657,0,0,1,11.4.362V3.72a7.674,7.674,0,0,0-1.77-.2,2.208,2.208,0,0,0-1.9.788,4.2,4.2,0,0,0-.686,2.734v1.2H11.4l-1.171,3.393Z"
        />
      </g>
    </svg>
  )
}

export default IconFacebook
