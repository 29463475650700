import create from "zustand"

interface FeedbackState {
  open: boolean
  text: string | null
  toggleFeedback: () => void
  setText: (text: string | null) => void
}

const useStore = create<FeedbackState>((set, get) => ({
  open: false,
  text: null,
  toggleFeedback: () => set({ open: !get().open }),
  setText: (text) => set({ text }),
}))

export const useFeedbackStore = useStore
