import axios from "axios"

export async function getLang() {
  return await axios({
    method: "get",
    url: "https://paternagroup.com/jd-admin/services/langService.php",
  })
    .then((res) => {
      console.log(res.data)
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
