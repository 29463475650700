export const tr = {
  translation: {
    routes: {
      aboutUs: "hakkimizda",
      ourBrands: "markalar",
      fieldsOfActivity: "faaliyet-alanlari",
      contact: "iletisim",
      reservation: "rezervasyon",
      career: "kariyer",
    },
    menu: {
      aboutUs: "Hakkımızda",
      ourBrands: "Markalarımız",
      fieldsOfActivity: "Faaliyet Alanları",
      contact: "İletişim",
      reservation: "DLM Lounge Rezervasyonu",
      career: "EKİBE KATILIN",
    },
    home: {
      info: {
        title: "Siz seyahat edin, biz yönetelim.",
        text: "Paterna Group 2009 yılında kurulmuş olup, o tarihten bu yana sahip olduğu vizyon ve inanç sayesinde sürekli organizasyon yapısını ve iş sahasını genişletmiştir.",
        btnText: "DETAYLI İNCELEYİN",
      },
      marqueeText: "Hizmetler",
      fields: {
        title: "Yiyecek içecek endüstrisi hızlı bir ivme kazanıyor.",
        desc: "Biz de bu hıza yetişerek profesyonel olarak bu endüstrinin restoran işletmeciliği, CIP işletmeciliği ve yiyecek tedariki olmak üzere 3 önemli alanı yönetmekteyiz.",
        cards: {
          catering: {
            title: "YEMEK HİZMETİ",
            detail: "Restoran & Market & Yemek Hizmeti",
          },
          cip: {
            title: "CIP SERVİSİ",
            detail: "DLM Lounge",
          },
          management: {
            title: "YÖNETİM",
            detail: "Ortak alan servisleri",
          },
        },
        btnText: { p1: "FAALİYET", p2: "ALANLARI" },
      },
      animated: {
        title: "Dalaman Havaalanı’nda zamanınızı en iyi şekilde değerlendirin.",
        text: "Geniş marka seçeneklerimizi uçuşunuzu beklerken deneyimleyin…",
        btnText: { p1: "MARKALARA", p2: "GÖZ ATIN" },
      },
    },
    aboutUs: {
      title: { p1: "", p2: "Paterna Group" },
      desc: {
        p1: "Paterna Group 2009 yılında kurulmuş olup, o tarihten bu yana sahip olduğu vizyon ve inanç sayesinde sürekli organizasyon yapısını ve iş sahasını genişletmiştir.",
        p2: "Paterna Group olarak, yer aldığımız YDA Dalaman Havalimanı İşletmesi bünyesinde, yerli ve yabancı misafirlerimize Yiyecek ve İçecek hizmeti ve Lounge, Catering, refreshment servisi, özel gün yiyecek içecek hizmeti (toplantı, festival, açılış, tanıtım) ve tekstil gibi çeşitli sektörlerde hizmet vermektedir.",
        p3: "Yemeğe dair her şeyi seven büyük bir ekibiz. Yeme-içmeye karşı duyduğumuz tutkuyu havalimanının hızlı dinamiği ile birleştiriyoruz.",
      },
      visMis: {
        vision: {
          title: "Vizyonumuz",
          text: {
            p1: "Hizmet verdiğimiz tüm noktalarda müşteri taleplerine en hızlı, en etkin ve en sağlıklı çözümler getirerek karşılıklı fayda sağlamak; Türkiye’de havalimanları ve sosyalleşme alanlarında bulunduğu coğrafyanın öncü şirketlerinden biri olmak.",
          },
        },
        mission: {
          title: "Misyonumuz",
          text: {
            p1: "Turizm alanında ve hizmet sektöründe yeni pazarlara nüfuz ederek, dikey ve yatay büyüme faaliyetleri ile organizasyonumuzu güçlendirmek ve edindiğimiz bu disiplini hizmet olarak çok geniş yelpazelerde büyümektir.",
            p2: "Yeni konseptler oluşturup benzersiz lezzetler sunmak ve “horeka sektöründe paydaşlarımızla birlikte var olmayı hedeflemektir.”",
          },
        },
      },
    },
    exploreOurBrands: {
      title: "Geniş marka seçeneklerimizi uçuşunuzu beklerken deneyimleyin…",
      btnText: { p1: "MARKALARI", p2: "KEŞFEDİN" },
    },
    fieldsOfActivity: {
      title: {
        p1: "Faaliyet",
        p2: "Alanları",
      },
      desc: "Yiyecek içecek endüstrisi hızlı bir ivme kazanıyor. Biz de bu hıza yetişerek profesyonel olarak bu endüstrinin restoran işletmeciliği, CIP işletmeciliği ve yiyecek tedariki olmak üzere 3 önemli alanı yönetmekteyiz.",
      sections: {
        s1: {
          title: "Havaalanı <br> Yiyecek & İçecek<br> Servisleri",
          text: {
            p1: "2009 yılından beri Dalaman Havaalanı dış hatlar terminalinde yiyecek ve içecek sektöründe faaliyet gösteren Paterna Group, modern mimarisiyle restore edilmiş ve tasarlanmış restoranlarıyla yolcular için tercih edilen yiyecek ve içecek durağı olmuştur.",
            p2: "Günümüzde, hem iç hem dış hatlarda bulunan 20’den fazla restoran, cafe ve market gibi yiyecek ve içecek sektörüne ait işletmenin operasyonlarını yürütmektedir.",
          },
        },
        s2: {
          title: "Catering, <br> Yiyecek İçecek & <br> Tedarik Hizmetleri",
          text: {
            p1: "Expo 2016 Uluslararası Tarım Fuarı ve Milyonfest 2018 Dalaman başta olmak üzere Dalaman Havalimanı dışında da, kendi alanında faaliyetlerini sürdüren Paterna Group, catering başta olmak üzere çeşitli üretim ve dağıtım işlemlerini kalite standartlarına uyarak gerçekleştirmektedir.",
          },
        },
        s3: {
          title: "Üretim <br> Tesisleri",
          text: {
            p1: "Paterna Group işletmekte olduğu birimlerinin yiyecek ve içecek ihtiyaçlarını karşılamak üzere 500 metrekare’den büyük bir alanın üzerine kurulmuş üretim tesisi ile bugün 2000’in üzerinde ürün üretmektedir.",
            p2: "Hijyen standartlarından hiçbir ödün vermeden yüksek üretim kapasitesinde faaliyet gösteren ve yüzlerce reçeteye sahip üretim departmanıyla her geçen gün kaliteli tariflerinin sayısını arttırmaktadır.",
          },
        },
        s4: {
          title: "Mağazacılık <br> Hizmetleri",
          text: {
            p1: "Yiyecek ve içecek sektörü dışında da havalimanı, alışveriş merkezi ve cadde gibi alanlarda mağazacılık hizmetleri verebilmektedir.",
          },
        },
      },
    },
    career: {
      title: {
        p1: "Ekibe",
        p2: "katılın.",
      },
      desc: "Paterna Group için nitelikli insan gücü vazgeçilmezdir. Şirketimizin tüm çalışanları belirli işe alım standartlarından geçmektedirler. Değerli çalışanlarımız, işe alım süreçlerini tamamladıktan sonra hizmet verdiğimiz bütün noktalarda misafirlerimizin memnuniyeti için çalışan değerli birer bireydirler.",
      form: {
        personalInfo: "Kişisel Bilgileriniz",
        name: "İSİM SOYİSİM*",
        phone: "TELEFON NO.*",
        email: "EPOSTA ADRESİ*",
        message: "MESAJINIZ",
        file: "CV'NİZİ YÜKLEYİN*",
        fileInfo: "Maksimum dosya boyutu 2MB'dir. Yalnızca pdf ve docx dosyaları yükleyebilirsiniz.",
        btnText: "ŞİMDİ BAŞVURUN",
      },
      personalDataForm: {
        text: "Kişisel Verilerin Korunması ve Bilgi Paylaşımı Formu",
        path: "https://paternagroup.com/content/tr/kisisel-veriler-ile-ilgili-kvkk-kanunu-kapsaminda-basvuru-formu.pdf",
      },
    },
    privacyPolicy: {
      text: "kapsamında kişisel verilerimin işlenmesine onay veriyorum.",
      link: {
        text: "Kişisel Verilerin Korunması ve Bilgi Paylaşımı",
        path: "https://paternagroup.com/content/tr/gizlilik-ve-cerez-politikasi.pdf",
      },
    },
    contact: {
      title: { p1: "Between you", p2: "and <br> the sky..." },
      btnText: { p1: "HARİTADA", p2: "GÖSTER" },
      addressLabels: {
        address: "ADRES",
        email: "EPOSTA",
        phone: "TELEFON",
      },
      form: {
        name: "İSİM SOYİSİM*",
        phone: "TELEFON NO.",
        email: "EPOSTA ADRESİ*",
        message: "MESAJINIZ*",
        btnText: "GÖNDER",
      },
      stickers: { ask: "Bize Ulaşın", contact: "Bize Ulaşın" },
      personalDataInfo: {
        text: "Kişisel Verilerin Korunması ve Bilgi Paylaşımı",
        path: "https://paternagroup.com/content/tr/kisisel-verileri-saklama-ve-imha-politikasi-metni.pdf",
      },
    },
    footer: {
      title: "DLM Lounge’da <br> yerinizi şimdi <br> ayırın!",
      btnText: { p1: "YERİNİZİ", p2: "AYIRIN" },
      sayHi: "Say Hi!",
      addressSection: {
        address: "ADRES",
        email: "EPOSTA",
        phone: "TELEFON",
        btnText: "YOL TARİFİ",
      },
      copyright: {
        allRights: "2022 © Paterna Group | Tüm Hakları Saklıdır.",
        privacyAndCookie: {
          text: "Gizlilik & Çerez Politikası",
          path: "https://paternagroup.com/content/tr/gizlilik-ve-cerez-politikasi.pdf",
        },
      },
      links: {
        aboutUs: "HAKKIMIZDA",
        ourBrands: "MARKALARIMIZ",
        fieldsOfActivity: "FAALİYET ALANLARI",
        contact: "İLETİŞİM",
        reservation: "DLM LOUNGE REZERVASYONU",
        career: "EKİBE KATILIN",
      },
    },
    ourBrands: {
      title: {
        p1: "Markalarımızı",
        p2: "Keşfedin",
      },
      desc: "Dalaman Havaalanı’nda zamanınızı en iyi şekilde değerlendirin. Geniş marka seçeneklerimizi uçuşunuzu beklerken deneyimleyin…",
      filter: {
        title: "Tüm Markalar",
      },
      card: {
        btnText: "İNCELEYİN",
      },
    },
    reservation: {
      title: "DLM Lounge’da yerinizi şimdi ayırın!",
      form: {
        name: {
          smallTop: "Kişisel Bilgiler",
          label: "AD SOYAD*",
        },
        phone: { label: "TELEFON NUMARASI*" },
        email: { label: "EPOSTA ADRESİ*" },
        passenger: {
          smallTop: "Yolcu Bilgileri",
          label: "YETİŞKİN",
          smallBottom: "+3 Yaş",
        },
        infant: {
          label: "BEBEK",
          smallBottom: "0-2 Yaş",
        },
        petInfo: {
          havePets: { label: "Evcil hayvanınız var mı?", yes: "EVET", no: "HAYIR" },
          addNewPet: "YENİ EKLE",
          petType: "HAYVAN TÜRÜ",
          petNumber: "SAYI",
          options: { o1: "KÖPEK", o2: "KEDİ", o3: "DİĞER" },
        },
        flightDate: {
          label: "UÇUŞ TARİHİ*",
        },
        flightTime: {
          label: "UÇUŞ SAATİ*",
        },
        selectAirlineCompany: {
          label: "BİR HAVAYOLU FİRMASI SEÇİN*",
        },
        flightNo: {
          label: "UÇUŞ NUMARASI*",
        },
        service: {
          smallTop: "Hangi Premium servisimize rezervasyon oluşturmak istersiniz?",
          label: "BİR SERVİS SEÇİN*",
          notes: {
            n1: "* 0-2 yaş arası için herhangi bir ücret talep edilmemektedir",
            n2: "* Online rezervasyon sistemimizi kullanarak %10 indirim kazanın",
          },
          options: {
            o1: "İç Hatlar CIP Lounge (Alkollü İçecekler)",
            o2: "İç Hatlar CIP Lounge (Alkolsüz İçecekler)",
          },
        },
        message: {
          label: "MESAJ",
        },
        btnText: "GÖNDER",
      },
    },
    brandDetail: {
      brandName: "Marka",
      brandType: "Kategori",
      reservation: "REZERVASYON",
      reservationLink: "https://paternagroup.com/rezervasyon",
      seeMenu: "MENU",
      airportLocation: "Havaalanındaki Konumu",
      nextBrand: { p1: "SONRAKİ", p2: "MARKA" },
    },
    titleAndDesc: {
      preTitle: "Paterna Group | ",
      home: {
        title: "Pre-flight Management.",
        desc: "Paterna Group 2009 yılında kurulmuş olup, o tarihten bu yana sahip olduğu vizyon ve inanç sayesinde sürekli organizasyon yapısını ve iş sahasını genişletmiştir.",
        canonical: "https://paternagroup.com",
      },
      aboutUs: {
        title: "Hakkımızda",
        desc: "Paterna Group olarak, yer aldığımız YDA Dalaman Havalimanı İşletmesi bünyesinde, yerli ve yabancı misafirlerimize Yiyecek ve İçecek hizmeti ve Lounge, Catering, refreshment servisi, özel gün yiyecek içecek hizmeti (toplantı, festival, açılış, tanıtım) ve tekstil gibi çeşitli sektörlerde hizmet vermektedir. Yemeğe dair her şeyi seven büyük bir ekibiz. Yeme-içmeye karşı duyduğumuz tutkuyu havalimanının hızlı dinamiği ile birleştiriyoruz.",
        canonical: "https://paternagroup.com/hakkimizda",
      },
      ourBrands: {
        title: "Markalar",
        desc: "Dalaman Havaalanı’nda zamanınızı en iyi şekilde değerlendirin. Geniş marka seçeneklerimizi uçuşunuzu beklerken deneyimleyin…",
        canonical: "https://paternagroup.com/markalar",
      },
      fieldsOfActivity: {
        title: "Faaliyet Alanları",
        desc: "Yiyecek içecek endüstrisi hızlı bir ivme kazanıyor. Biz de bu hıza yetişerek profesyonel olarak bu endüstrinin restoran işletmeciliği, CIP işletmeciliği ve yiyecek tedariki olmak üzere 3 önemli alanı yönetmekteyiz.",
        canonical: "https://paternagroup.com/faaliyet-alanlari",
      },
      contact: {
        title: "İletişim",
        desc: "Between you and the sky...",
        canonical: "https://paternagroup.com/iletisim",
      },
      career: {
        title: "Kariyer",
        desc: "Paterna Group için nitelikli insan gücü vazgeçilmezdir. Şirketimizin tüm çalışanları belirli işe alım standartlarından geçmektedirler. Değerli çalışanlarımız, işe alım süreçlerini tamamladıktan sonra hizmet verdiğimiz bütün noktalarda misafirlerimizin memnuniyeti için çalışan değerli birer bireydirler.",
        canonical: "https://paternagroup.com/kariyer",
      },
      reservation: {
        title: "Rezervasyon",
        desc: "DLM Lounge’da yerinizi şimdi ayırın!",
        canonical: "https://paternagroup.com/rezervasyon",
      },
    },
  },
}
