import s from "../assets/scss/ButtonHorizontal.module.scss"

import cx from "classnames"
import { useState } from "react"
import { useWindowSize } from "hooks"
import { breakpoints } from "typess"

type Props = {
  icon?: JSX.Element | string
  text: string
  backgroundColor?: string
  externalHover?: boolean
}

const ButtonHorizontal = ({ icon, text, backgroundColor }: Props) => {
  const windowSize = useWindowSize()
  const [hovered, setHovered] = useState<boolean>(false)

  const hover = () => {
    if (windowSize.width && windowSize.width <= breakpoints.tablet) {
      return
    }
    setHovered((prev) => !prev)
  }

  return (
    <div
      className={s.buttonHorizontal}
      style={{
        backgroundColor: hovered ? backgroundColor : "transparent",
        transform: `rotate(${hovered ? 7 : 0}deg)`,
      }}
      onMouseEnter={hover}
      onMouseLeave={hover}
    >
      {
        <div className={cx(s.btnInner, { [s.withText]: text })}>
          {icon && <div className={s.iconW}>{icon}</div>}
          <p className={s.text}>{text}</p>
        </div>
      }
    </div>
  )
}

export default ButtonHorizontal
